import {useEffect} from 'react'

export const RequireHttps = () => {

	useEffect(() => {
		if (window.location.protocol === 'http:') {
			// Replace 'http://' with 'https://' and reload the page
			window.location.href = window.location.href.replace(/^http:/, 'https:')
		}
	}, [])

	return null
}
