import {
	USERLOGIN,
	USERLOGOUT,
	STOREPROFILE,
	TOGGLESIDEBAR,
	TOGGLEDCINVENTORY,
	TOGGLENOTE,
	TOGGLEREPAIRS,
	TOGGLETABLEDROPDOWN,
	TOGGLEDATAPROVIDER,
	ADD_TOAST,
	REMOVE_TOAST,
	INVENTORY_FILTERS,
	CLEAR_INVENTORY_FILTERS,
	SET_CURRENT_SPACE,
	SET_NOTIFICATIONS_DATA,
	UPDATE_CONVERSATIONS_COUNTER,
	UPDATE_MARKETPLACES_FILTERS,
	SET_WATCH_COLUMNS_CONFIGS,
} from './appActions'
import assignIn from 'lodash/assignIn'

export const userLoggedIn = (state = '', {type, payload}) => {
	switch (type) {
		case USERLOGIN:
			return payload.loggedin

		case USERLOGOUT:
			return payload.loggedin

		default:
			return state
	}
}

export const userProfile = (state = {}, {type, payload}) => {
	switch (type) {
		case STOREPROFILE:
			return payload.profile

		default:
			return state
	}
}

export const toggleSidebar = (state = {}, {type, payload}) => {
	switch (type) {
		case TOGGLESIDEBAR:
			return payload.isOpenSideBar

		default:
			return state
	}
}
export const toggleDetailsCardInventory = (state = {}, {type, payload}) => {
	switch (type) {
		case TOGGLEDCINVENTORY:
			return payload.isOpenDetailsInventory

		default:
			return state
	}
}
//

export const toggleNote = (state = {}, {type, payload}) => {
	switch (type) {
		case TOGGLENOTE:
			return payload.isOpenNote

		default:
			return state
	}
}

export const toggleRepairs = (state = {}, {type, payload}) => {
	switch (type) {
		case TOGGLEREPAIRS:
			return payload.isOpenRepairs

		default:
			return state
	}
}

export const toggleTableDropDown = (state = {}, {type, payload}) => {
	switch (type) {
		case TOGGLETABLEDROPDOWN:
			return payload.visibleTableDropDown

		default:
			return state
	}
}

export const toggleDataProvider = (state = {}, {type, payload}) => {
	switch (type) {
		case TOGGLEDATAPROVIDER:
			return payload.dataProvider

		default:
			return state
	}
}

export const inventoryFilters = (state = [], action) => {
	const {payload, type} = action

	switch (type) {
		case INVENTORY_FILTERS:
			return (() => {
				if (!Object.keys(payload).length) {
					return []
				}

				if (state.length === 0) {
					action.payload.isPrimary = true
				}

				return [payload, ...state.filter(filter => filter.key !== payload.key)] // make sure to remove the previous key if any exist
			})()
		default:
			return state
	}
}

export const clearInventoryFilters = (state = [], action) => {
	const {type} = action

	switch (type) {
		case CLEAR_INVENTORY_FILTERS:
			return (() => {
				return Math.random()
			})() // clear the state.
		default:
			return state
	}
}

export const toasts = (state = [], action) => {
	const {payload, type} = action

	switch (type) {
		case ADD_TOAST:
			return [payload, ...state]

		case REMOVE_TOAST:
			return state.filter(toast => toast.id !== payload)

		default:
			return state
	}
}

export const currentSpaceReducer = (state = {}, action) => {
	const {payload, type} = action

	switch (type) {
		case SET_CURRENT_SPACE:
			return payload

		default:
			return state
	}
}

export const notificationsDataReducer = (state = {count: 0, unopenedCount: 0, notifications: []}, action) =>{
	const {payload, type} = action;

	switch (type) {
		case SET_NOTIFICATIONS_DATA:
			const newState = assignIn({}, state, payload)
			return newState
		default:
			return state
	}
}

export const marketplaceFiltersReducer = (state = {activeTab: 'home'}, action) => {
	const {payload, type} = action

	switch (type) {
		case UPDATE_MARKETPLACES_FILTERS:
			const newState = assignIn({}, state, payload)
			return newState
		default:
			return state
	}
}

export const conversationsCounterReducer = (state = {
	sellThreadsCount: 0,
	unreadSellThreadsCount: 0,
	buyThreadsCount: 0,
	unreadBuyThreadsCount: 0
}, action) => {
	const {payload, type} = action

	switch (type) {
		case UPDATE_CONVERSATIONS_COUNTER:
			const newState = assignIn({}, state, payload)
			return newState
		default:
			return state
	}
}

export const watchColumnsConfigsReducer = (state = [], action) => {
	const {payload, type} = action

	switch (type) {
		case SET_WATCH_COLUMNS_CONFIGS:
			return payload
		default:
			return state
	}
}

