import {Globals} from './../Context'
import {Buffer} from 'buffer'

const ProcessUpload = (file, chunksize, Progress, Done, Error, uploadTo) => {
	let uniquefilename,
		fileSize,
		fileType,
		index = 0,
		start,
		end,
		chunks,
		lead = 0

	var fileReader = new FileReader()

	const Start = () => {
		try {
			//Start the upload by sending the first chunk of the file after that keep offsetting the values of start and end.
			if (index === 0) {
				start = 0
				end = start + chunksize
			}
			fileSize = file.size //Add the file size
			fileType = file.type //Add the file size

			var blob = file.slice(start, end) //Slice the file it the appropriate position.
			fileReader.readAsArrayBuffer(blob) //Read from the block as an array of buffers server won't accept anyother type.
			fileReader.onloadend = evt => {
				//Once the reader is ready submit the chunk into the server with the unique name attached to it.

				var buffer = Buffer.from(evt.target.result),
					bundle = {
						buffer: buffer,
						done: end >= fileSize,
						chunks: chunks,
						chunksize: chunksize,
						fileSize: fileSize,
						fileType: fileType,
						index: index,
						uniquefilename: uniquefilename,
					}
				let uploadUrl = uploadTo ?? 'upload'
				// Globals.socket.emit(uploadUrl, bundle, response => {
				// 	if (response.state) {
				// 		//Continue
				// 		if (!response.end && end < fileSize) {
				// 			//Offset all the values to get the next chunk.
				// 			start += chunksize
				// 			end = start + chunksize
				// 			lead = Math.min((end / fileSize) * 100, 100)
				// 			Progress(lead, file) //Send the progress back.
				// 			index++
				// 			setTimeout(() => {
				// 				//Set some delay.
				// 				Start() //Redo the process with the chunk values updated.
				// 			}, 100)
				// 		} else if (response.end) {
				// 			//Server set the end to true,
				// 			//Make sure to set the lead to 100;
				// 			lead = 100
				// 			Progress(lead, file) //Send the progress back.
				// 			file.upload = response
				// 			Done(file)
				// 		}
				// 	} else {
				// 		Error('File upload was interupted', file)
				// 	}
				// })
			}
		} catch (error) {
			Error('File upload was interupted', file)
		}
	}

	let uploadImages = () => {
		//upload images
		const formData = new FormData()
		formData.append('attachment[file]', file)
		formData.append('attachment[order]', 1)
		Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/attachments`, formData)
			.then(response => {
				if (response.status && response.status >= 200 && response.status < 300) {
					const imgData = {id: response.data.id, order: response.data.order}
					lead = 100
					Progress(lead, file) //Send the progress back.
					file.upload = response.data
					Done(file)
				}
			})
			.catch(err => {
				console.error(err)
			})
		//
	}
	uploadImages()

	/*
	axios
		.post(`${Globals.wsurl}/uniquefy`)
		.then(response => {
			uniquefilename = response.data
			chunks = Math.round(file.size / chunksize)
			Start() //Start uploading.
		})
		.catch(err => {})
		*/
}

export default ProcessUpload
