import React, {Component} from 'react'
import {Globals} from '../../Context'
import {connect} from 'react-redux'
import {useParams} from 'react-router-dom'

const withParams = Component => {
	return props => <Component {...props} params={useParams()} />
}

class DryConfirmation extends Component {
	constructor() {
		super()
		this.state = {}
	}

	componentDidMount() {}

	justDoIt = $event => {
		$event.preventDefault()

		try {
			const {token} = this.props.params
			//This is for a frech profile with a space creation.
			return Globals.Axios()
				.post(`${Globals.wsurl}/dry/confirmation/ping`, {
					pass: this.emailInput.value,
					token: token,
				})
				.then(response => {
					if (!response.data.status) return window.alert('Something went wrong please try again later.')

					return window.alert('All good and approved.')
				})
				.catch(error => {
					return window.alert('Something went wrong please try again later.')
				})
		} catch (error) {
			console.log(error)
		}
	}

	render() {
		return (
			<div className="fixed top-0 left-0 bottom-0 right-0">
				<div className="container mx-auto p-4 h-screen flex items-center justify-center">
					<div className="bg-white max-w-lg p-5 rounded-2xl shadow-gray-200 shadow-md w-full">
						<div className="relative">
							<div className="mt-2">
								<label htmlFor="detail" className="text-base bg-transparent font-normal py-2 block">
									<svg className="inline w-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
										{' '}
										<path
											clipRule="evenodd"
											fillRule="evenodd"
											d="M10 2.5c-1.31 0-2.526.386-3.546 1.051a.75.75 0 01-.82-1.256A8 8 0 0118 9a22.47 22.47 0 01-1.228 7.351.75.75 0 11-1.417-.49A20.97 20.97 0 0016.5 9 6.5 6.5 0 0010 2.5zM4.333 4.416a.75.75 0 01.218 1.038A6.466 6.466 0 003.5 9a7.966 7.966 0 01-1.293 4.362.75.75 0 01-1.257-.819A6.466 6.466 0 002 9c0-1.61.476-3.11 1.295-4.365a.75.75 0 011.038-.219zM10 6.12a3 3 0 00-3.001 3.041 11.455 11.455 0 01-2.697 7.24.75.75 0 01-1.148-.965A9.957 9.957 0 005.5 9c0-.028.002-.055.004-.082a4.5 4.5 0 018.996.084V9.15l-.005.297a.75.75 0 11-1.5-.034c.003-.11.004-.219.005-.328a3 3 0 00-3-2.965zm0 2.13a.75.75 0 01.75.75c0 3.51-1.187 6.745-3.181 9.323a.75.75 0 11-1.186-.918A13.687 13.687 0 009.25 9a.75.75 0 01.75-.75zm3.529 3.698a.75.75 0 01.584.885 18.883 18.883 0 01-2.257 5.84.75.75 0 11-1.29-.764 17.386 17.386 0 002.078-5.377.75.75 0 01.885-.584z"
										/>
									</svg>
									<span>••••••</span>
								</label>
								<div className="outlin relative w-full">
									<input
										ref={emailInput => (this.emailInput = emailInput)}
										type="text"
										name="detail"
										placeholder="••••••"
										className="rounded border block p-4 w-full bg-white appearance-none outline-none text-black text-sm"
									/>
								</div>
								<button className="mt-5 w-full p-4 rounded text-white cursor-pointer bg-blue font-medium uppercase" onClick={this.justDoIt}>
									<span>Just Do it!</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state, props) => {
	return {}
}

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(withParams(DryConfirmation))
