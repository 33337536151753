const NotFound = () => {

	return (
        <div className="h-screen flex items-center justify-center">
        <div className="flex flex-col items-center">
          <img src="/no_results_found.png" alt="No Results Found" className="w-[130px]" />
          <p className="text-lg text-[#5D6E81] mt-4">No Watch Found</p>
        </div>
      </div>
	)
}

export default NotFound
