import React, { useState, useEffect } from 'react'
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DialogContentText from '@mui/material/DialogContentText'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'

const CustomMobileModal = styled(Dialog)(({ theme }) => ({
	'& .MuiDialog-container': {
		height: '100%',
		display: 'flex',
		alignItems: 'flex-end',
	},
	'& .MuiDialog-paper': {
		width: '100%',
		maxWidth: '100%',
        maxHeight: '65%',
		margin: 0,
        borderRadius: '10px 10px 0px 0px',
	},
}));

const FilterByOptionModal = ({open, onClose, filterOption, dataSelected, onApplyFilter}) => {
    const [itemsSelected, setItemsSelected] = useState(dataSelected[filterOption.id]);
    const [filteredOptions, setFilteredOptions] = useState(filterOption.options);
    const showSearch = ['brand', 'reference_number', 'series'].includes(filterOption.id);

    const handleClearAll = () => {
        setItemsSelected([]);
    }

    useEffect(() => {
        setItemsSelected(dataSelected[filterOption.id]);
        setFilteredOptions(filterOption.options);
    }, [filterOption])

    const handleApply = () => {
        onApplyFilter(filterOption.id, itemsSelected)
        onClose();
    }

    const handleClose = () => {
        setItemsSelected(dataSelected[filterOption.id])
        onClose();
    }

    const handleSearchInFilter = (e) => {
        const value = e.target.value.toLowerCase();
        const filteredOptions = filterOption.options.filter((option) => option.toLowerCase().includes(value));
        setFilteredOptions(filteredOptions);
    }

    return (
        <CustomMobileModal
            open={open}
            onClose={onClose}
        >
            <DialogTitle
                className="bg-[#4B7CBE] rounded-t-[10px]"
            >
                <div className="flex justify-between items-center">
                    <span className="font-bold text-white uppercase">{filterOption.label}</span>
                    <CloseIcon className="cursor-pointer font-bold text-white" onClick={handleClose} />
                </div>
            </DialogTitle>
            <DialogContent className="mt-2">
                {showSearch &&
                    <>
                        <TextField
                            placeholder="Search here"
                            sx={{
                                width: '100%',
                                textAlign: 'center',
                                margin: '12px auto 0px auto',
                                height: '40px',
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                style: { height: '40px' }
                            }}
                            onInput={handleSearchInFilter}
                        />
                        <div className="flex justify-end mb-1 mt-2">
                            <span
                                className="text-[14px] text-[#5D6E81] underline cursor-pointer font-bold"
                                onClick={() => setItemsSelected(filterOption.options)}
                            >
                                SELECT ALL
                            </span>
                            <span
                                className="text-[14px] text-[#5D6E81] ms-4 underline cursor-pointer font-bold"
                                onClick={handleClearAll}
                            >
                                CLEAR
                            </span>
                        </div>
                    </>
                }
                <DialogContentText>
                    <Stack
                        divider={<Divider orientation="horizontal" flexItem />}
                        spacing={2}
                    >
                        {filteredOptions.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={
                                        clsx(
                                            'flex items-center justify-between',
                                            {
                                            "text-[#4B7CBE]":itemsSelected.includes(item)
                                            }
                                        )
                                    }
                                    onClick={() => {
                                        if (itemsSelected.includes(item)) {
                                            setItemsSelected(itemsSelected.filter((i) => i !== item));
                                        } else {
                                            setItemsSelected([...itemsSelected, item]);
                                        }
                                    }}
                                >
                                    <div>{item}</div>
                                    {itemsSelected.includes(item) &&
                                        <CheckIcon />
                                    }
                                </div>
                            )
                        })}
                    </Stack>
                </DialogContentText>
            </DialogContent>
            <div className="mx-6 h-[1px] bg-[#D9E2EE]"></div>
            <Stack direction="row" className="mx-auto mb-6 mt-2" spacing={2}>
                <Button
                    onClick={handleClearAll}
                    color="inherit"
                    variant="outlined"
                    sx={{ textTransform: 'none', borderColor: '#DBDDE1' }}
                    className="w-[106px] h-[40px] normal-case"
                >
                    Clear all
                </Button>

                <Button
                    onClick={handleApply}
                    color="primary"
                    variant="contained"
                    sx={{ textTransform: 'none', backgroundColor: '#4B7CBE' }}
                    className="w-[106px] h-[40px] normal-case"
                    autoFocus
                >
                    Done
                </Button>
            </Stack>
        </CustomMobileModal>
    )
}

export default FilterByOptionModal
