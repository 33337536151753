import React, {useState, useEffect} from 'react'
import Autosuggest from 'react-autosuggest'

import './styles.css'
import {Globals} from '../../../Context'
import uniqueId from 'lodash/uniqueId'
import isNil from 'lodash/isNil'

const CustomerAutoSuggest = props => {
	const {handleChangeAutoComplete, extraValueErrors, extraValue, selectedVal, handleChange, isMobile, className} = props

	const [value, setValue] = useState(extraValue || "")
	const [autosuggestId, setAutosuggestId] = useState(undefined)
	const [suggestions, setSuggestions] = useState([])

	const renderSuggestion = (suggestion) => (
		<>
			{suggestion.name === 'Create A Memo' ?
				<div className="flex justify-center text-xs	py-2 px-4 font-semibold" disabled={true}>
					Create A { selectedVal === 'memo_to' ?  'Memo' : 'Order'}
				</div> :
				<div className="flex justify-between py-2 px-4 cursor-pointer hover:text-[#2083F1]">
					<span className="hover:text-[#2083F1] truncate">
						{suggestion.company_name} ({suggestion.name})
					</span>
					<div className='flex items-center'>
						<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M6.99998 2.10001C7.38657 2.10001 7.69998 2.41341 7.69998 2.80001V6.30001H11.2C11.5866 6.30001 11.9 6.61341 11.9 7.00001C11.9 7.3866 11.5866 7.70001 11.2 7.70001H7.69998V11.2C7.69998 11.5866 7.38657 11.9 6.99998 11.9C6.61338 11.9 6.29998 11.5866 6.29998 11.2V7.70001H2.79998C2.41338 7.70001 2.09998 7.3866 2.09998 7.00001C2.09998 6.61341 2.41338 6.30001 2.79998 6.30001L6.29998 6.30001V2.80001C6.29998 2.41341 6.61338 2.10001 6.99998 2.10001Z" fill="currentColor"/>
						</svg>
					</div>
				</div>
			}
		</>
	  );

	const onChange = (event, {newValue}) => {
		if(newValue.name === "Create A Memo") return
		if(event._reactName === "onClick") {
			setValue(newValue.company_name)
			handleChangeAutoComplete(event, newValue)
		} else {
			if(event.code === "Enter") {
				setValue(newValue.company_name)
				handleChangeAutoComplete(event, newValue)
			} else {
				handleChange(event)
				setValue(newValue)
			}
		}
	}

	const onSuggestionsFetchRequested = ({value}) => {
		const filterParams = `q[name_cont]=${value}&q[company_name_cont]=${value}&q[m]=or`

		Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/customers?${filterParams}`)
			.then(response => {
				if (response.status && response.status >= 200 && response.status < 300) {
					if(response.data.customers.length > 0) {
						response.data.customers.unshift({name: 'Create A Memo'})
					}
					setSuggestions(response.data.customers)
				}
			})
			.catch(error => {
				return this.props.addToast({
					text: 'Something went wrong please try again later.',
					type: 'error',
				})
			})
	}

	const onSuggestionsClearRequested = () => {
		setSuggestions([])
	}

	useEffect(() => {
		setAutosuggestId(uniqueId('customer_autosuggest_'))
	}, [])

	const inputProps = {
		type: 'text',
		name: 'status_details',
		placeholder: isMobile ? 'Name' : 'name',
		className: className ? className : `rounded-[5px] h-full px-[14px] py-[16px] w-full border-[1px] outline-blue-600 md:px-[0px] md:py-2 md:rounded-[0px] md:border-[0px] placeholder:text-[#5D6E81] placeholder:text-[14px] md:outline-black md:border-b ${!isNil(extraValueErrors) && 'border-red-500'}`,
		value,
		onChange,
	}

	return (
		<Autosuggest
			suggestions={suggestions}
			onSuggestionsFetchRequested={onSuggestionsFetchRequested}
			onSuggestionsClearRequested={onSuggestionsClearRequested}
			getSuggestionValue={suggestion => suggestion}
			renderSuggestion={renderSuggestion}
			containerProps={{
				className: 'w-full customer',
			}}
			inputProps={inputProps}
			id={autosuggestId}
		/>
	)
}

export default CustomerAutoSuggest
