export const statusesCollection = []

export const inventoryConditionsCollection = [
	{option: 'Unworn', value: 'unworn'},
	{option: 'Like New', value: 'like_new'},
	{option: 'Excellent', value: 'excellent'},
	{option: 'Very Good', value: 'very_good'},
	{option: 'Good', value: 'good'},
	{option: 'Fair', value: 'fair'},
]

export const inventoryStatusesCollection = [
	{value: 'on_hand', option: 'On Hand'},
	{value: 'transit_from', option: 'Transit From'},
	{value: 'memo_to', option: 'Memo to'},
	{value: 'on_hold_for', option: 'On Hold for'},
	{value: 'pending_sale_to', option: 'Pending Sale to'},
	{value: 'sold_to', option: 'Sold to'},
	{value: 'repair_with', option: 'Repair with'},
	{value: 'other', option: 'Other'},
]
