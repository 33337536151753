import {createContext, useState} from 'react'
import Toasts from '../Components/Toast/Toasts'

export const SettingVar = createContext()
const SettingsVariable = ({children}) => {
	const [OpenAddUser, setOpenAddUser] = useState(false)
	const [sendemail, setsendemail] = useState(false)

	return (
		<SettingVar.Provider
			value={{
				OpenAddUser,
				setOpenAddUser,
				sendemail,
				setsendemail,
			}}
		>
			{children}
			<Toasts />
		</SettingVar.Provider>
	)
}

export default SettingsVariable
