
import {Globals} from '../Context'
import isNull from 'lodash/isNull'
import isFunction from 'lodash/isFunction'

export const onPressEscape = (callback) => {
    return (event) => {
        if (event.key === Globals.keyboardButton.escape) {
            callback(event)
        }
    }
}

export const findOptionByCharacter = (event, itemRefs, removeFirstArray = true) => {
    const key = event?.key?.toLowerCase();
    let items = itemRefs
    if(removeFirstArray) {
        items = itemRefs.slice(1);
    }

    const foundIndex = items.findIndex(option => {
        if(isNull(option)  || isNull(option.current)) return
        if(!isNull(option.current) && !!option.current) {
            return option.current.textContent.toLowerCase().startsWith(key)
        } else if (!isNull(option) && !!option) {
            return option.textContent.toLowerCase().startsWith(key)
        }
    });

    if(foundIndex > -1) {
        if(!!(items[foundIndex]?.current)) {
            items[foundIndex]?.current?.focus();
        } else {
            items[foundIndex].focus();
        }
    }
}

export const focusElement = (element) => {
	const parentNode = document.querySelectorAll(element)
    if(parentNode.length > 0) {
        parentNode[0].focus()
    }
}

export const goToPreviousOptionInventory = (currentIndex, nextIndex, itemRefs) => {
    if(currentIndex > 0) {
        nextIndex = currentIndex - 1
        itemRefs[nextIndex].focus();
    }
}

export const goToNextOptionInventory = (currentIndex, nextIndex, itemRefs, deleteAllFilterRef, callback) => {
	if(currentIndex < itemRefs.length - 1) {
        if(!deleteAllFilterRef.current && currentIndex === 0) {
            callback()
            return
        }
        nextIndex = currentIndex + 1
        itemRefs[nextIndex].focus();
    }
}

export const onPressFocusSearchInput = (event, searchInput, callback) => {
    if (event.key === 'f' && (event.ctrlKey || event.metaKey)) {
        event.preventDefault();
        searchInput?.focus();
        if(isFunction(callback)) {
            callback()
        }
        event.stopPropagation()
    }
}

export const handleKeyDownPickerDate = (event) => {
    if(event.target.value === undefined) return
    if (event.key === '/' && event.target.value.endsWith('/')) {
        event.preventDefault();
    }
    if(event.target.value[1] === '/') {
        event.target.value = "0" + event.target.value
    }
    if(event.target.value[4] === '/') {
        event.target.value = event.target.value.slice(0, 3) + "0" + event.target.value[3]
    }
    if(event.target.value.length === 2 && event.key !== "Backspace" && event.key !== "/") {
        if(event.target.value[1] === '/') return
        event.target.value = event.target.value + '/'
    } else if(event.target.value.length === 5 && event.key !== "Backspace" && event.key !== "/") {
        if(event.target.value[4] === '/') return
        event.target.value = event.target.value + '/'
    }
}


