import classNames from "classnames"
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

const Input = ({name, title, type, placeholder, defaultValue, onchange, value, className, disableTitle, isPassword, showPassword, handleSetShowPassWord, disableDefaultClass = false, disabled=false}) => {
	return (
		<div className={disableDefaultClass ? '' : 'px-1'}>
			{!disableTitle && <h3 className="text-sm lg:text-base font-normal leading-6 my-2 lg:my-2">{title}</h3>}
			<div className={classNames("", {
				"relative w-full": isPassword,
				"px-1": !disableDefaultClass
			})}>
				{
					isPassword &&
					<div
						onClick={handleSetShowPassWord}
						className="absolute right-[16px] top-[50%] cursor-pointer translate-y-[-50%] text-[#A2ACBA]"
					>
						{showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
					</div>
				}

				<input
					disabled={disabled}
					name={name}
					defaultValue={defaultValue}
					value={value}
					placeholder={placeholder}
					onChange={onchange}
					className={
						classNames(className ? className : 'input h-8 lg:h-12 rounded-md w-full px-3 text-xs font-normal leading-5 lg:text-sm', {
						'pr-[45px]': isPassword
					})}
					type={isPassword && showPassword ? 'text' : type}
					style={{border: '1px solid #D9E2EE'}}
				/>
			</div>
		</div>
	)
}

export default Input
