import {connect, useSelector, useDispatch} from 'react-redux'
import Classnames from 'classnames'
import {NavLink} from 'react-router-dom'
import {useState} from 'react'
import LogoutOutlined from '@ant-design/icons/LogoutOutlined';
import { isMobile } from 'react-device-detect';

import { toggleSidebar, logOut } from '../app/appActions'

const watchIcon = (
	<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 16 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
		<path d="M10.31 2L10.72 4.48C9.87 4.17 8.96 4 8 4C7.05 4 6.13 4.17 5.29 4.47L5.7 2H10.31ZM10.72 19.52L10.31 22H5.7L5.29 19.53C6.13 19.83 7.05 20 8 20C8.96 20 9.87 19.83 10.72 19.52ZM12 0H4L3.05 5.73C1.19 7.19 0 9.45 0 12C0 14.55 1.19 16.81 3.05 18.27L4 24H12L12.96 18.27C14.81 16.81 16 14.54 16 12C16 9.46 14.81 7.19 12.96 5.73L12 0ZM8 18C4.69 18 2 15.31 2 12C2 8.69 4.69 6 8 6C11.31 6 14 8.69 14 12C14 15.31 11.31 18 8 18Z" />{' '}
	</svg>
)

const soldIcon = (
	<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
		<path
			clipRule="evenodd"
			fillRule="evenodd"
			d="M5.5 3A2.5 2.5 0 003 5.5v2.879a2.5 2.5 0 00.732 1.767l6.5 6.5a2.5 2.5 0 003.536 0l2.878-2.878a2.5 2.5 0 000-3.536l-6.5-6.5A2.5 2.5 0 008.38 3H5.5zM6 7a1 1 0 100-2 1 1 0 000 2z"
		/>
	</svg>
)

const marketplaceIcon = (
	<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 19.99 18" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
		<path d="M19.89,5.89l-1.05-4.37c-.22-.9-1-1.52-1.91-1.52H3.04c-.9,0-1.69,.63-1.9,1.52L.09,5.89c-.24,1.02-.02,2.06,.62,2.88,.08,.11,.19,.19,.28,.29v6.94c0,1.1,.9,2,2,2h14c1.1,0,2-.9,2-2v-6.94c.09-.09,.2-.18,.28-.28,.64-.82,.87-1.87,.62-2.89Zm-2.99-3.9l1.05,4.37c.1,.42,.01,.84-.25,1.17-.14,.18-.44,.47-.94,.47-.61,0-1.14-.49-1.21-1.14l-.58-4.86h1.93Zm-5.91,0h1.96l.54,4.52c.05,.39-.07,.78-.33,1.07-.22,.26-.54,.41-.95,.41-.67,0-1.22-.59-1.22-1.31V2ZM6.48,6.52l.55-4.52h1.96V6.69c0,.72-.55,1.31-1.29,1.31-.34,0-.65-.15-.89-.41-.25-.29-.37-.68-.33-1.07Zm-4.45-.16L3.04,2h1.97l-.58,4.86c-.08,.65-.6,1.14-1.21,1.14-.49,0-.8-.29-.93-.47-.27-.32-.36-.75-.26-1.17Zm.96,9.64v-6.03c.08,0,.15,.03,.23,.03,.87,0,1.66-.36,2.24-.95,.6,.6,1.4,.95,2.31,.95s1.65-.36,2.23-.93c.59,.57,1.39,.93,2.29,.93,.84,0,1.64-.35,2.24-.95,.58,.59,1.37,.95,2.24,.95,.08,0,.15-.02,.23-.03v6.03H2.99Z" />
	</svg>
)

const messagesIcon = (
	<svg className="w-6 h-6" stroke="currentColor" fill="none" viewBox="0 0 20 19" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
		<path
			d="M6 7H6.01M10 7H10.01M14 7H14.01M7 13H3C1.89543 13 1 12.1046 1 11V3C1 1.89543 1.89543 1 3 1H17C18.1046 1 19 1.89543 19 3V11C19 12.1046 18.1046 13 17 13H12L7 18V13Z"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

const memoIcon = (
	<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 18 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
		<path d="M16.5 1.5L15 0L13.5 1.5L12 0L10.5 1.5L9 0L7.5 1.5L6 0L4.5 1.5L3 0L1.5 1.5L0 0V20L1.5 18.5L3 20L4.5 18.5L6 20L7.5 18.5L9 20L10.5 18.5L12 20L13.5 18.5L15 20L16.5 18.5L18 20V0L16.5 1.5ZM16 17.09H2V2.91H16V17.09ZM3 13H15V15H3V13ZM3 9H15V11H3V9ZM3 5H15V7H3V5Z" />
	</svg>
)

const salesIcon = (
	<svg className="w-6 h-6" stroke="currentColor" fill="none" viewBox="0 0 16 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
		<path
			d="M5 12L11 6M5.50003 6.5H5.51003M10.5 11.5H10.51M15 19V3C15 1.89543 14.1046 1 13 1H3C1.89543 1 1 1.89543 1 3V19L4.5 17L8 19L11.5 17L15 19ZM6 6.5C6 6.77614 5.77614 7 5.5 7C5.22386 7 5 6.77614 5 6.5C5 6.22386 5.22386 6 5.5 6C5.77614 6 6 6.22386 6 6.5ZM11 11.5C11 11.7761 10.7761 12 10.5 12C10.2239 12 10 11.7761 10 11.5C10 11.2239 10.2239 11 10.5 11C10.7761 11 11 11.2239 11 11.5Z"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

const customersIcon = (
	<svg className="w-6 h-6" stroke="currentColor" fill="none" viewBox="0 0 22 18" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
		<path
			d="M16 17H21V15C21 13.3431 19.6569 12 18 12C17.0444 12 16.1931 12.4468 15.6438 13.1429M16 17H6M16 17V15C16 14.3438 15.8736 13.717 15.6438 13.1429M6 17H1V15C1 13.3431 2.34315 12 4 12C4.95561 12 5.80686 12.4468 6.35625 13.1429M6 17V15C6 14.3438 6.12642 13.717 6.35625 13.1429M6.35625 13.1429C7.0935 11.301 8.89482 10 11 10C13.1052 10 14.9065 11.301 15.6438 13.1429M14 4C14 5.65685 12.6569 7 11 7C9.34315 7 8 5.65685 8 4C8 2.34315 9.34315 1 11 1C12.6569 1 14 2.34315 14 4ZM20 7C20 8.10457 19.1046 9 18 9C16.8954 9 16 8.10457 16 7C16 5.89543 16.8954 5 18 5C19.1046 5 20 5.89543 20 7ZM6 7C6 8.10457 5.10457 9 4 9C2.89543 9 2 8.10457 2 7C2 5.89543 2.89543 5 4 5C5.10457 5 6 5.89543 6 7Z"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

const analyticsIcon = (
	<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 18 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
		<path d="M9 1L9.44721 0.105573C9.16569 -0.0351909 8.83431 -0.0351909 8.55279 0.105573L9 1ZM17 5H18C18 4.62123 17.786 4.27496 17.4472 4.10557L17 5ZM1 5L0.552786 4.10557C0.214002 4.27496 0 4.62123 0 5H1ZM17 15L17.4472 15.8944C17.786 15.725 18 15.3788 18 15H17ZM9 19L8.55279 19.8944C8.83431 20.0352 9.16569 20.0352 9.44721 19.8944L9 19ZM1 15H0C0 15.3788 0.214002 15.725 0.552786 15.8944L1 15ZM8.55279 1.89443L16.5528 5.89443L17.4472 4.10557L9.44721 0.105573L8.55279 1.89443ZM16.5528 4.10557L8.55279 8.10557L9.44721 9.89443L17.4472 5.89443L16.5528 4.10557ZM9.44721 8.10557L1.44721 4.10557L0.552786 5.89443L8.55279 9.89443L9.44721 8.10557ZM1.44721 5.89443L9.44721 1.89443L8.55279 0.105573L0.552786 4.10557L1.44721 5.89443ZM16.5528 14.1056L8.55279 18.1056L9.44721 19.8944L17.4472 15.8944L16.5528 14.1056ZM9.44721 18.1056L1.44721 14.1056L0.552786 15.8944L8.55279 19.8944L9.44721 18.1056ZM2 15V5H0V15H2ZM18 15V5H16V15H18ZM8 9V19H10V9H8Z" />
	</svg>
)

const SideBar = props => {
	const {profile} = useSelector(state => state)

	let {isOpen} = props,
		classNameNavLink = Classnames('text-sm tracking-wider ml-3', {block: isOpen === true}, {hidden: isOpen === false})
	const isAccessible = ['owner', 'admin', 'editor'].includes(profile?.role)
	const navigationLinksClass = 'text-gray-500 md:px-6 sm:px-3 xs:px-3 xxs:px-3 flex items-center cursor-pointer'

	const dispatch = useDispatch(),
		toggleSideBar = () => {
			if (window.innerWidth <= 768) {
				dispatch(toggleSidebar(!isOpen))
			}
		}

	const navigationLinks = [
		<div className={navigationLinksClass}>
			<div className="flex mobile-view-sidebar sm:pt-10 xs:pt-10 xxs:pt-10">
				<span>
					<img src="/logo-trans.png" className=" relative" width="217" height="30" alt="Elefta" />
				</span>
				<span className="mb-2 pt-2">
					<button
						className="mr-3 cursor-pointer relative top-3 left-3"
						onClick={() => {
							toggleSideBar()
						}}
					>
						<svg className="cursor-pointer w-5" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M0 12H9V10H0V12ZM0 7H18V5H0V7ZM0 2H18V0H0V2Z" fill="#5D6E81" />
						</svg>
					</button>
				</span>
			</div>
		</div>,

		<NavLink
			to="/inventory"
			className={navigationLinksClass}
			onClick={() => {
				toggleSideBar()
			}}
		>
			<span>{watchIcon}</span>
			<span className={classNameNavLink}>Inventory</span>
		</NavLink>,
		<NavLink
			to="/inventory-sold"
			className={navigationLinksClass}
			onClick={() => {
				toggleSideBar()
			}}
		>
			<span>{soldIcon}</span>
			<span className={classNameNavLink}>Sold Inventory</span>
		</NavLink>,
		<NavLink
			to="/marketplace"
			className={navigationLinksClass}
			onClick={() => {
				toggleSideBar()
			}}
		>
			<span>{marketplaceIcon}</span>
			<span className={classNameNavLink}>Marketplace</span>
		</NavLink>,
		isAccessible && (
			<NavLink
				to="/messages"
				className={navigationLinksClass}
				onClick={() => {
					toggleSideBar()
				}}
			>
				<span>{messagesIcon}</span>
				<span className={classNameNavLink}>Messages</span>
			</NavLink>
		),
		isAccessible && (
			<NavLink
				to="/memo"
				className={navigationLinksClass}
				onClick={() => {
					toggleSideBar()
				}}
			>
				<span>{memoIcon}</span>
				<span className={classNameNavLink}>Memo</span>
			</NavLink>
		),
		isAccessible && (
			<NavLink
				to="/Sales"
				className={navigationLinksClass}
				onClick={() => {
					toggleSideBar()
				}}
			>
				<span>{salesIcon}</span>
				<span className={classNameNavLink}>Sales</span>
			</NavLink>
		),
		isAccessible && (
			<NavLink
				to="/customers"
				className={navigationLinksClass}
				onClick={() => {
					toggleSideBar()
				}}
			>
				<span>{customersIcon}</span>
				<span className={classNameNavLink}>Customers</span>
			</NavLink>
		),
		isAccessible && (
			<NavLink
				to="/dashboard"
				className={navigationLinksClass}
				onClick={() => {
					toggleSideBar()
				}}
			>
				<span>{analyticsIcon}</span>
				<span className={classNameNavLink}>Analytics</span>
			</NavLink>
		),
		(
			isMobile &&
			<NavLink
				onClick={() => {
					logOut()
				}}
				to="/login"
				className={navigationLinksClass}
			>
				<span><LogoutOutlined style={{ fontSize: '24px' }}/></span>
				<span className={classNameNavLink}>Log Out</span>
			</NavLink>
		)
	]

	return (
		<>
			{isOpen && <div className="bg-black/50 w-full absolute z-40 h-screen sm:block xs:block xxs:block 2xl:hidden lg:hidden md:hidden"></div>}

			<nav className={Classnames('hidden bgPrimary flex pt-6 lg:static top-0  z-20 left-menu md:block', {'w-max': !isOpen, 'w-64': isOpen})}>
				<div className="relative pt-7 w-full">
					<div className={Classnames('space-y-10', {'left-menu-icon': isOpen === false})}>
						{navigationLinks.map((navComponent, index) => (
							<div key={index}>{navComponent}</div>
						))}
					</div>
				</div>
			</nav>
			<nav className={Classnames('bgPrimary flex pt-6 lg:static top-0 z-20 sm:z-[9999] left-menu md:hidden', {'w-max': !isOpen, 'max-w-[229px]': isOpen, 'sm:max-w-[300px] w-[300px]': isOpen, 'sm:hidden': !isOpen })}>
				<div className="relative pt-7 sm:pt-0 md:w-full">
					<div className={Classnames('space-y-10 md:hidden ', {'left-menu-icon': isOpen === false})}>
						{navigationLinks.map((navComponent, index) => (
							<div key={index}>{navComponent}</div>
						))}
					</div>
				</div>
			</nav>
		</>
	)
}

const mapStateToProps = (state, props) => {
	return {
		profile: state.profile,
	}
}

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(SideBar)
