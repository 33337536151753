import { useEffect } from 'react'
import { Globals } from '../Context'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import {addToast, setCurrentSpace} from './../app/appActions'
import find from 'lodash/find'

const Watch = props => {
    const { id } = useParams();
    const navigate = useNavigate()

    const openApp = (spaceId, watchId) => {
        const customUrlScheme = `com.elefta.app://space/${spaceId}/InventoryDetails/${watchId}`;

        try {
            // Attempt to open the app using the custom URL scheme
            window.location.href = customUrlScheme;
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        Globals.New_Axios().get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/watches/${id}/space_id`).then(response => {
            const { space_id, watch_id } = response.data

            if (!props.currentSpace?.id) {
                // User not login
                openApp(space_id, watch_id)

                // This will force user to login page
                navigate(`/inventory`, { state: { watch: { id: watch_id }, isScanned: true } });
            } else {
                const space = find(props.profile.spaces, { id: space_id})

                if (!space) {
                    // Current user does not have access to the watch
                    // render 404
                    navigate('/not-found')
                    return;
                }

                props.setCurrentSpace(space)
                Globals.Cache.set(Globals.USERSELECTEDSPACE, JSON.stringify(space))
                Globals.SELECTEDSPACEID = space_id;

                openApp(space_id, watch_id)
                navigate(`/inventory`, { state: { watch: { id: watch_id }, isScanned: true } });
            }
		})
		.catch(error => {
            navigate('/not-found')
		})
    }, []);

	return (
        <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center z-50">
            <button
                type="button"
                className="inline-flex items-center px-4 py-2 leading-6 text-sm shadow rounded-md text-white bg-blue transition ease-in-out duration-150 cursor-not-allowed"
                disabled
            >
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    {' '}
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                    <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                </svg>
                <span>Loading... </span>
            </button>
        </div>
	)
}

const mapStateToProps = (state, props) => {
	return {
        currentSpace: state.currentSpace,
        profile: state.profile,
    }
}

const mapActionsToProps = {
	addToast,
    setCurrentSpace,
}

export default connect(mapStateToProps, mapActionsToProps)(Watch)
