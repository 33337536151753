import TopTitlePage from '../TopTitlePage'

const TopElements = () => {
	return (
		<div className="flex lg:flex-row flex-col lg:items-center items-start lg:pl-0 pl-3 relative  Whitecontainer lg:bg-white border-b pb-5">
			<div className="flex relative w-full lg:pl-5">
				<TopTitlePage title="Add Inventory" />
			</div>
		</div>
	)
}

export default TopElements
