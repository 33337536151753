import {Globals} from '../Context'

const isAppcuesLoaded = () => {
	if (!window.Appcues) {
		console.error('Appcues is not loaded.')
		return false
	}
	return true
}

const isProduction = () => process.env.NODE_ENV === 'production'

const AppcuesService = {
	initialize: async function (userId, userProperties) {
		if (!isProduction()) return Promise.reject('Not in production environment')
		if (!isAppcuesLoaded()) return Promise.reject('Appcues is not loaded')

		return new Promise((resolve, reject) => {
			try {
				window.Appcues.identify(userId, {
					...userProperties,
				})
				resolve()
			} catch (error) {
				reject(error)
			}
		})
	},

	updateUserInfo: async function (userProperties) {
		if (!isProduction()) return Promise.reject('Not in production environment')
		if (!isAppcuesLoaded()) return Promise.reject('Appcues is not loaded')

		let currentUserId = Globals.currentUserId || ''

		if (!currentUserId) {
			console.error('AppcuesService: User ID not set. Call initialize first.')
			return Promise.reject('User ID not set.')
		}

		return new Promise((resolve, reject) => {
			try {
				window.Appcues.identify(currentUserId, {
					...userProperties,
				})
				resolve()
			} catch (error) {
				reject(error)
			}
		})
	},

	reset: function () {
		if (!isProduction()) return
		if (!isAppcuesLoaded()) return
		window.Appcues.reset()
	},

	// show: function (flowId) {
	// 	if (!isProduction()) return
	// 	if (!isAppcuesLoaded()) return
	// 	window.Appcues.show(flowId)
	// },

	// track: function (eventName, eventProperties) {
	// 	if (!isProduction()) return
	// 	if (!isAppcuesLoaded()) return
	// 	window.Appcues.track(eventName, eventProperties)
	// },
}

export default AppcuesService
