import React from 'react'
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContentText from '@mui/material/DialogContentText'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'

const CustomMobileModal = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-container': {
        height: '100%',
        display: 'flex',
        alignItems: 'flex-end',
    },
    '& .MuiDialog-paper': {
        width: '100%',
        maxWidth: '100%',
        margin: 0,
        borderRadius: '10px 10px 0px 0px',
    },
}));

const FilterModal = ({ open, onClearFilter, onClose, filtersConfigData, dataSelected, onChooseFilter, onApplyFilter }) => {
    const handleApplyFilter = () => {
        onApplyFilter()
        onClose()
    }

    return (
        <CustomMobileModal
            open={open}
            onClose={onClose}
        >
            <DialogTitle
                id="alert-dialog-title"
                className="bg-[#4B7CBE] rounded-t-[10px]"
            >
                <div className="flex justify-between items-center">
                    <span className="font-bold text-white uppercase">Filters</span>
                    <CloseIcon className="cursor-pointer font-bold text-white" onClick={onClose} />
                </div>
            </DialogTitle>
            <DialogContent sx={{ p: 0 }}>
                <DialogContentText id="alert-dialog-description">
                    <Stack
                        divider={<Divider sx={{ mt: 0 }} orientation="horizontal" flexItem />}
                    >
                        {filtersConfigData.map((filterItem, index) => {
                            return (
                                <div
                                    key={index}
                                    className="flex items-center justify-between active:bg-[#F1F1F1] p-4 rounded-[5px] cursor-pointer"
                                    onClick={onChooseFilter.bind(this, filterItem.id)}
                                >
                                    <div>
                                        <div className='uppercase'>{filterItem.label}</div>
                                        <div className="text-[10px] text-[#2C79E0]">{dataSelected[filterItem.id].join(', ')}</div>
                                    </div>
                                    <div>
                                        <ChevronRightIcon />
                                    </div>
                                </div>
                            )
                            }
                        )}
                    </Stack>
                </DialogContentText>
            </DialogContent>
            <Stack direction="row" className="mx-auto mb-8" spacing={2}>
                <Button
                    onClick={onClearFilter}
                    variant="outlined"
                    color="inherit"
                    sx={{ textTransform: 'none', borderColor: '#DBDDE1' }}
                    className="w-[106px] h-[40px] whitespace-nowrap normal-case"
                    >
                    Clear all
                </Button>
                <Button
                    onClick={handleApplyFilter}
                    color="primary"
                    variant="contained"
                    sx={{ textTransform: 'none', backgroundColor: '#4B7CBE'}}
                    className="w-[106px] h-[40px] normal-case"
                    autoFocus
                >
                    Apply
                </Button>
            </Stack>
        </CustomMobileModal>
    )
}

export default FilterModal
