import React from 'react'
import { isChrome } from 'react-device-detect';

function updateBannerHeight() {
    const element = document.getElementById('banner-wanning');
    if (!!element) {
        const elementHeight = element.offsetHeight;
        document.documentElement.style.setProperty('--banner-wanning-height', `${elementHeight}px`);
        const width  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

        if (width < 768) {
            document.body.style.paddingTop = `${elementHeight}px`;
        } else {
            document.body.style.paddingTop = "unset";
        }
    }
}

document.addEventListener("DOMContentLoaded", () => {
    setTimeout(() => {
        document.documentElement.style.setProperty('--banner-wanning-height', `0px`);
        updateBannerHeight()
    }, 500)
});


const BannerWarning = props => {
    window.addEventListener('resize', updateBannerHeight);

    if (isChrome) {
        return null
    }

    return (
        <div className='hidden bg-[#dcedf6] text-center fixed md:relative z-[999] top-0 md:block' id="banner-wanning">
            <div className='pl-12 p-4 text-[16px] font-medium'>
                We suggest using Google Chrome for the best experience. Other browsers may have limited functionality.
            </div>
        </div>
    )
}

export default BannerWarning
