import React, {Component} from 'react'
import {Globals} from '../../Context'
import {connect} from 'react-redux'
import Classnames from 'classnames'

import {logOut, addToast } from '../../app/appActions'
import AppcuesService from '../../services/AppcuesService'
import {buildSpaceProperties} from '../../utils/AppcuesProperties'
import { increaseUserSignInCount } from '../../utils/UserSessionHelper'
import isEmpty from 'lodash/isEmpty'

class Pickspace extends Component {
	constructor() {
		super()
		this.state = {
			spaces: [],
			loadingSpaces: false,
			createSpace: false,

			formErrors: {},
			formData: {},
		}
	}

	componentDidMount() {
		this.setState({loadingSpaces: true})

		Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/me`)
			.then(response => {
				if (response.status && response.status === 200) {

					return this.setState({
						loadingSpaces: false,
						spaces: response.data.spaces,
					})
				}
			})
			.catch(error => {
				this.setState({loadingSpaces: false})
			})
	}

	switchSpaces = space => {
		Globals.Cache.set(Globals.USERSELECTEDSPACE, JSON.stringify(space))
		Globals.Cache.setCookie(Globals.USERSELECTEDSPACE, JSON.stringify(space))
		Globals.SELECTEDSPACEID = space.id;
		localStorage.setItem('loginSpace', Date.now());

		increaseUserSignInCount(space)

		AppcuesService.updateUserInfo(buildSpaceProperties(space)).then(() => {
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		}).catch(error => {
			console.warn(error)
			window.location.reload();
		})

		/*
		Globals.Axios()
			.post(`${Globals.wsurl}/user/space/connect/user`, {
				data: {
					space: space.id,
				},
			})
			.then(response => {
				if (response.data.status && response.data.token) {
					Globals.Cache.set(Globals.TokenKey, response.data.token)
					Globals.Cache.setCookie(Globals.TokenKey, response.data.token)
					// return navigate('/dashboard');
					return window.location.reload()
				}
			})
			.catch(error => {
				console.log(error)
			})
			*/
	}

	handleChange = $event => {
		this.setState(
			{
				formData: {
					...this.state.formData,
					[$event.target.name]: $event.target.value.trim(),
				},
			},
			e => console.log(this.state.formData)
		)
	}

	handleSubmit = _event => {
		_event.preventDefault()

		let errors = {}
		const state = this.state

		//Make sure all the data is being correct.
		if (isEmpty(state.formData.company_name)) errors = {...errors, company_name: 'Company name is required'}
		if (isEmpty(state.formData.company_address)) errors = {...errors, company_address: 'Company address is required'}
		if (isEmpty(state.formData.city)) errors = {...errors, city: 'Company city is required'}
		if (isEmpty(state.formData.state)) errors = {...errors, state: 'Company state is required'}
		if (isEmpty(state.formData.zip_code)) errors = {...errors, zip_code: 'Company zip code is required'}
		if (isEmpty(state.formData.country)) errors = {...errors, country: 'Company country is required'}
		if (isEmpty(state.formData.phone)) errors = {...errors, phone: 'Company phone is required'}

		this.setState({formErrors: errors})

		if (!isEmpty(errors)) return

		this.setState({loadingSpaces: true})
		//This is for a frech profile with a space creation.
		const formData = new FormData()
		formData.append('space[name]', state.formData.company_name)
		formData.append('space[street]', state.formData.company_address)
		formData.append('space[city]', state.formData.city)
		formData.append('space[state]', state.formData.state)
		formData.append('space[country]', state.formData.country)
		formData.append('space[zip_code]', state.formData.zip_code)
		formData.append('space[phone_number]', state.formData.phone)
		formData.append('space[resale_certificate]', state.formData.certificate)

		return Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces`, formData)
			.then(response => {
				this.setState({loadingSpaces: false})
				if (response.status && response.status >= 200 && response.status < 300) {
					Globals.Cache.set(Globals.USERSELECTEDSPACE, JSON.stringify(response.data))
					AppcuesService.updateUserInfo(buildSpaceProperties(response.data)).then(() => {
						console.warn('Update user info')
						window.location.reload();
					}).catch(error => {
						console.warn(error)
						window.location.reload();
					})
				}

				errors = {...errors, error: response.data.errors}
				return this.setState({formErrors: errors})
			})
			.catch(error => {
				this.setState({loadingSpaces: false})
				if (error.response?.data?.message) {
					const message = error.response?.data?.message;
					const searchTerm = 'Name';
					const indexOfFirst = message.indexOf(searchTerm);
					if (indexOfFirst >= 0) {
						const updatedMessage = message.replace(searchTerm, "Company " + searchTerm);
						this.props.addToast({
							text: updatedMessage,
							type: 'error',
						})
					} else {
						this.props.addToast({
							text: error.response?.data?.message,
							type: 'error',
						})
					}
				} else {
					this.props.addToast({
						text: 'Something went wrong, please try again',
						type: 'error',
					})
				}
				this.setState(
					{
						formData: {},
					}
				)
			})
	}

	render() {
		const state = this.state
		const displayError = key => {
			if (!isEmpty(this.state.formErrors[key])) return <div className="inline-block text-sm my-2 rounded bg-red-200 p-1 px-4 font-medium text-red-700">{this.state.formErrors[key]}</div>
		}

		return (
			<div className="fixed top-0 left-0 bottom-0 right-0 h-screen">
				<div className="items-center container mx-auto p-4 flex justify-center h-full">
					<div className="bg-white max-w-lg p-5 rounded-2xl shadow-gray-200 shadow-md w-full h-auto max-h-screen overflow-scroll">
						<div className='h-fit'>
							<div className="relative">
								<div className="absolute right-0 space-x-4 top-0">
									{/* <button className="font-medium text-xs text-blue" onClick={e => this.setState({createSpace: true})}>
										<span>
											<svg className="inline mr-1 text-yellow-500 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
												{' '}
												<path d="M15.98 1.804a1 1 0 00-1.96 0l-.24 1.192a1 1 0 01-.784.785l-1.192.238a1 1 0 000 1.962l1.192.238a1 1 0 01.785.785l.238 1.192a1 1 0 001.962 0l.238-1.192a1 1 0 01.785-.785l1.192-.238a1 1 0 000-1.962l-1.192-.238a1 1 0 01-.785-.785l-.238-1.192zM6.949 5.684a1 1 0 00-1.898 0l-.683 2.051a1 1 0 01-.633.633l-2.051.683a1 1 0 000 1.898l2.051.684a1 1 0 01.633.632l.683 2.051a1 1 0 001.898 0l.683-2.051a1 1 0 01.633-.633l2.051-.683a1 1 0 000-1.898l-2.051-.683a1 1 0 01-.633-.633L6.95 5.684zM13.949 13.684a1 1 0 00-1.898 0l-.184.551a1 1 0 01-.632.633l-.551.183a1 1 0 000 1.898l.551.183a1 1 0 01.633.633l.183.551a1 1 0 001.898 0l.184-.551a1 1 0 01.632-.633l.551-.183a1 1 0 000-1.898l-.551-.184a1 1 0 01-.633-.632l-.183-.551z" />
											</svg>
										</span>
										<span>Create new space</span>
									</button> */}
									<button className="font-medium text-xs text-blue" onClick={logOut}>
										Logout
									</button>
								</div>
								<h2 className="font-medium text-xl mb-1">Spaces</h2>
								{!isEmpty(state.spaces) && <p className="text-xs">Pick a space from the list.</p>}
							</div>

							{state.loadingSpaces && (
								<div className="my-10 flex items-center justify-center p-5">
									<div className="w-full text-center" role="status">
										<svg
											aria-hidden="true"
											className="inline mr-2 w-10 h-10 text-gray-200 animate-spin dark:text-gray-200 fill-blue-500"
											viewBox="0 0 100 101"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
												fill="currentColor"
											/>
											<path
												d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
												fill="currentFill"
											/>
										</svg>
										<span className="block font-medium text-xs">Loading...</span>
									</div>
								</div>
							)}

							{!isEmpty(state.spaces) && (
								<div
									className={Classnames("max-h-96 min-h-14 overflow-scroll", {"h-96": !((isEmpty(state.spaces) || state.createSpace) && !state.loadingSpaces)})}
								>
									<ul className="my-5 p-0">
										{state.spaces.map(space => {
											return (
												<li key={space.id} className="border rounded my-2">
													<button className="w-full text-left p-3 hover:bg-gray-100" onClick={this.switchSpaces.bind(this, space)}>
														{space.name}
													</button>
												</li>
											)
										})}
									</ul>
								</div>
							)}

							{(isEmpty(state.spaces) || state.createSpace) && !state.loadingSpaces && (
								<div className="mt-2">
									<form onSubmit={this.handleSubmit}>
										{isEmpty(state.spaces) && <p className="text-xs">It seems like you don't have any space in your account, you can create one by filling the form below</p>}
										<div className="max-h-96 mt-5 mb-10 overflow-y-scroll">
											<h2>
												<strong>Company Details</strong>
											</h2>
											<div className="gap-2 grid grid-cols-1">
												<div className="mt-2">
													<label htmlFor="company_name" className="hidden text-base bg-transparent font-normal py-2">
														Company Name*
													</label>
													<div className="outlin relative w-full">
														<input
															type="text"
															name="company_name"
															placeholder=" "
															className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
															onChange={this.handleChange}
														/>
														<label htmlFor="company_name" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
															Company name
														</label>
													</div>
													{displayError('company_name')}
												</div>
												<div className="mt-2">
													<label htmlFor="company address" className="hidden text-base bg-transparent font-normal py-2">
														Company Address*
													</label>
													<div className="outlin relative w-full">
														<input
															type="text"
															name="company_address"
															placeholder=" "
															role="presentation"
															autocomplete="off"
															className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
															onChange={this.handleChange}
														/>
														<label htmlFor="company address" className="pointer-events-none absolute top-1  text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
															Company Address
														</label>
													</div>
													{displayError('company_address')}
												</div>
												<div className="mt-2">
													<label htmlFor="city" className="hidden text-base bg-transparent font-normal py-2">
														City*
													</label>
													<div className="outlin relative w-full">
														<input
															type="text"
															name="city"
															placeholder=" "
															className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
															onChange={this.handleChange}
														/>
														<label htmlFor="city" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
															City*
														</label>
													</div>
													{displayError('city')}
												</div>
												<div className="mt-2">
													<label htmlFor="state" className="hidden text-base bg-transparent font-normal py-2">
														State*
													</label>
													<div className="outlin relative w-full">
														<input
															type="text"
															name="state"
															placeholder=" "
															className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
															onChange={this.handleChange}
														/>
														<label htmlFor="state" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
															State*
														</label>
													</div>
													{displayError('state')}
												</div>
												<div className="mt-2">
													<label htmlFor="country" className="hidden text-base bg-transparent font-normal py-2">
														Choose a country*
													</label>
													<div className="outlin relative w-full">
														<select
															id="country"
															name="country"
															className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
															defaultValue=""
															onChange={this.handleChange}
														>
															<option value="" disabled>
																Choose a country*
															</option>
															{Globals.countries.map(c => (
																<option key={c.code} value={c.code}>
																	{c.name}
																</option>
															))}
														</select>
													</div>
													{displayError('country')}
												</div>
												<div className="mt-2">
													<label htmlFor="zip_code" className="hidden text-base bg-transparent font-normal py-2">
														Zip Code*
													</label>
													<div className="outlin relative w-full">
														<input
															type="text"
															name="zip_code"
															placeholder=" "
															className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
															onChange={this.handleChange}
														/>
														<label htmlFor="zip_code" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
															Zip Code*
														</label>
													</div>
													{displayError('zip_code')}
												</div>
												<div className="mt-2">
													<label htmlFor="phone" className="hidden text-base bg-transparent font-normal py-2">
														Phone Number*
													</label>
													<div className="outlin relative w-full">
														<input
															type="tel"
															name="phone"
															placeholder=" "
															className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
															onChange={this.handleChange}
														/>
														<label htmlFor="phone" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
															Phone Number*
														</label>
													</div>
													{displayError('phone')}
												</div>
												<div className="mt-2">
													<label htmlFor="Resale Certificate" className="hidden text-base bg-transparent font-normal py-2">
														Resale Certificate
													</label>
													<div className="outlin relative w-full">
														<input
															type="text"
															name="certificate"
															placeholder=" "
															className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
															onChange={this.handleChange}
														/>
														<label htmlFor="certificate" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
															Resale Certificate
														</label>
													</div>
												</div>
											</div>
											<div> {displayError('error')} </div>
											<div className="w-full mt-4">
												<button className="w-full p-4 rounded text-white cursor-pointer bg-blue font-medium uppercase" type="submit">
													<span>Create space</span>
												</button>
											</div>
										</div>
									</form>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state, props) => {
	return {
		profile: state.profile,
	}
}

const mapActionsToProps = {
	addToast
}

export default connect(mapStateToProps, mapActionsToProps)(Pickspace)
