export const buildSpaceProperties = space => {
	if (!space) return {}

	return {
		accountId: space.id,
		accountName: space.account_name,
		accountRole: space.role,
		companyStatus: space.status,
		city: space.city,
		street: space.street,
		zipCode: space.zip_code,
		state: space.state,
		country: space.country,
		website: space.website,
		slug: space.slug,
		companyEmail: space.email,
		companyName: space.name,
		companyPhoneNumber: space.phone_number,
	}
}

export const buildUserProperties = user => {
	if (!user) return {}

	return {
		acceptedTosAt: user.accepted_tos_at,
		confirmedAt: user.confirmed_at,
		friendlyId: user.friendly_id,
		lastSignInAt: user.last_sign_in_at,
		email: user.email,
		createdAt: new Date().toISOString(),
		firstName: user.first_name,
		lastName: user.last_name,
		role: user.role,
		status: user.status,
	}
}
