import React, {useEffect, useState} from 'react'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import {handleKeyDownPickerDate} from '../../utils/keyboardHandler'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'

const DatePickerFlat = ({name, title, defaultValue, handlechangePicker, isMobile, isMobileInventory, isVisibleEditMobile}) => {
	const [startDate, setStartDate] = useState(null);

	useEffect(() => {
		if (!isEmpty(defaultValue) && defaultValue !== 'null' && !isMobile) {
			const _defaultValue = new Date(defaultValue)
			if (_defaultValue instanceof Date && !isNaN(_defaultValue)) {
				setStartDate(_defaultValue)
			}
		}
	}, [defaultValue, isMobile])

	const textFieldNameMobile = () => {
		if(name === 'warranty') {
			return 'Warranty Date'
		} else if(name === 'date_purchased') {
			return 'Date Purchased'
		}
	}

	return (
		<React.Fragment>
			<div className="hidden relative date-flat-picker md:block">
				<DatePicker
					selected={startDate}
					onChange={date => {
						if(date === null) return
						// NOTE: UPDATE TIME ZONE
						const selectedDateWithCurrentTime = new Date(
							date.getFullYear(),
							date.getMonth(),
							date.getDate(),
							0, // Set hour to 00 (midnight)
							0, // Set minutes to 00
							0  // Set seconds to 00
						  );

						  setStartDate(selectedDateWithCurrentTime);

						  if (isFunction(handlechangePicker)) {
							handlechangePicker(selectedDateWithCurrentTime, name);
						  }
					}}
					showPopperArrow={false}
					name={name}
					value={startDate}
					placeholderText=" MM/DD/YYYY"
					onKeyDown={(event, name) => {
						handleKeyDownPickerDate(event)
					}}
				/>
				<svg className="absolute pointer-events-none right-0 top-0" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M15.8333 18.3334H4.16667C3.24619 18.3334 2.5 17.5872 2.5 16.6667V5.00008C2.5 4.07961 3.24619 3.33341 4.16667 3.33341H5.83333V1.66675H7.5V3.33341H12.5V1.66675H14.1667V3.33341H15.8333C16.7538 3.33341 17.5 4.07961 17.5 5.00008V16.6667C17.5 17.5872 16.7538 18.3334 15.8333 18.3334ZM4.16667 8.33341V16.6667H15.8333V8.33341H4.16667ZM4.16667 5.00008V6.66675H15.8333V5.00008H4.16667ZM14.1667 15.0001H12.5V13.3334H14.1667V15.0001ZM10.8333 15.0001H9.16667V13.3334H10.8333V15.0001ZM7.5 15.0001H5.83333V13.3334H7.5V15.0001ZM14.1667 11.6667H12.5V10.0001H14.1667V11.6667ZM10.8333 11.6667H9.16667V10.0001H10.8333V11.6667ZM7.5 11.6667H5.83333V10.0001H7.5V11.6667Z"
						fill="#5D6E81"
					/>
				</svg>
			</div>
			{
				!isMobileInventory ?
				<div className="relative date-flat-picker-custom md:hidden">
					<DatePicker
						selected={startDate}
						onChange={date => {
							setStartDate(date)
							if (isFunction(handlechangePicker)) handlechangePicker(date, name)
						}}
						showPopperArrow={false}
						name={name}
						value={startDate}
						placeholderText="Date"
						onKeyDown={(event, name) => {
							handleKeyDownPickerDate(event)
						}}
					/>
					{!isVisibleEditMobile &&
						<svg className="absolute pointer-events-none right-0 top-0" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M15.8333 18.3334H4.16667C3.24619 18.3334 2.5 17.5872 2.5 16.6667V5.00008C2.5 4.07961 3.24619 3.33341 4.16667 3.33341H5.83333V1.66675H7.5V3.33341H12.5V1.66675H14.1667V3.33341H15.8333C16.7538 3.33341 17.5 4.07961 17.5 5.00008V16.6667C17.5 17.5872 16.7538 18.3334 15.8333 18.3334ZM4.16667 8.33341V16.6667H15.8333V8.33341H4.16667ZM4.16667 5.00008V6.66675H15.8333V5.00008H4.16667ZM14.1667 15.0001H12.5V13.3334H14.1667V15.0001ZM10.8333 15.0001H9.16667V13.3334H10.8333V15.0001ZM7.5 15.0001H5.83333V13.3334H7.5V15.0001ZM14.1667 11.6667H12.5V10.0001H14.1667V11.6667ZM10.8333 11.6667H9.16667V10.0001H10.8333V11.6667ZM7.5 11.6667H5.83333V10.0001H7.5V11.6667Z"
								fill="#5D6E81"
							/>
						</svg>
					}
				</div> : <div className="relative date-flat-picker-custom z-[9] md:hidden">
					<DatePicker
						selected={startDate}
						onChange={date => {
							setStartDate(date)
							if (isFunction(handlechangePicker)) handlechangePicker(date, name)
						}}
						showPopperArrow={false}
						name={name}
						value={startDate}
						className='bg-red'
						placeholderText={textFieldNameMobile()}
						onKeyDown={(event, name) => {
							handleKeyDownPickerDate(event)
						}}
					/>
					<svg className="absolute pointer-events-none left-[112px] top-[1px]" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M15.8333 18.3334H4.16667C3.24619 18.3334 2.5 17.5872 2.5 16.6667V5.00008C2.5 4.07961 3.24619 3.33341 4.16667 3.33341H5.83333V1.66675H7.5V3.33341H12.5V1.66675H14.1667V3.33341H15.8333C16.7538 3.33341 17.5 4.07961 17.5 5.00008V16.6667C17.5 17.5872 16.7538 18.3334 15.8333 18.3334ZM4.16667 8.33341V16.6667H15.8333V8.33341H4.16667ZM4.16667 5.00008V6.66675H15.8333V5.00008H4.16667ZM14.1667 15.0001H12.5V13.3334H14.1667V15.0001ZM10.8333 15.0001H9.16667V13.3334H10.8333V15.0001ZM7.5 15.0001H5.83333V13.3334H7.5V15.0001ZM14.1667 11.6667H12.5V10.0001H14.1667V11.6667ZM10.8333 11.6667H9.16667V10.0001H10.8333V11.6667ZM7.5 11.6667H5.83333V10.0001H7.5V11.6667Z"
							fill="#5D6E81"
						/>
					</svg>
				</div>
			}
		</React.Fragment>
	)
}

export default DatePickerFlat
