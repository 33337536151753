// server.autosuggest.js
import React from 'react'
import Autosuggest from 'react-autosuggest'
import './autosuggest.css'
import {Globals} from '../../Context'
import Tooltip from '@mui/material/Tooltip'
import uniqueId from 'lodash/uniqueId'

class ServerAutoSuggest extends React.Component {
	constructor() {
		super()

		//Define state for value and suggestion collection
		this.state = {
			value: '',
			suggestions: [],
			autosuggestId: undefined,
		}
	}

	UNSAFE_componentWillMount() {
		this.setState({
			autosuggestId: uniqueId('server-auto-suggest-'),
		})
	}

	// Filter logic
	getSuggestions = async value => {
		const inputValue = value.trim().toLowerCase()
		this.props.setFormValue({reference_number: value.trim()})
		const response = await Globals.New_Axios().get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/common_watches?q[reference_number_i_cont]=${inputValue}&per_page=50`)
		return response.data.common_watches

		//let data = await response.json()
		//return data
	}

	// Trigger suggestions
	getSuggestionValue = suggestion => {
		this.props.setFormValue(suggestion)
		return suggestion.reference_number
	}

	// Render Each Option
	renderSuggestion = suggestion => (
		<div className="relative">
			<span className="sugg-option">
				<span className="icon-wrap">
					<img src="watch_icon.png" alt="imag" />
				</span>
				<span className="main-span">
					<span className="reference">{suggestion.reference_number}</span>

					<span className="tagLine">
						<div className="tab">
							<div className="heading-left">
								<h2>Dial</h2>
								<Tooltip title={suggestion.dial} placement="bottom" arrow>
									<p style={{fontWeight: 'bold'}}>{suggestion.dial.length > 30 ? suggestion.dial.slice(0, 30) + '...' : suggestion.dial}</p>
								</Tooltip>
							</div>

							<div className="flex flex-col items-end float-right pr-[10px] break-all">
								<h2>Bracelet</h2>
								<Tooltip title={suggestion.bracelet} placement="bottom" arrow>
									<p style={{fontWeight: 'bold'}}>
										{suggestion.bracelet.length > 20
											? suggestion.dial.length > 30
												? suggestion.bracelet.slice(0, 10) + '...'
												: suggestion.bracelet.slice(0, 20) + '...'
											: suggestion.bracelet}
									</p>
								</Tooltip>
							</div>
						</div>
					</span>
					{/*
						<span className="tagLine">{Globals.getFormattedTitle(suggestion, true)}</span>
					*/}
				</span>
			</span>
		</div>
	)

	// OnChange event handler
	onChange = (event, {newValue}) => {
		this.setState({
			value: newValue,
		})
	}

	// Suggestion rerender when user types
	onSuggestionsFetchRequested = ({value}) => {
		this.getSuggestions(value).then(data => {
			if (data.Error) {
				this.setState({
					suggestions: [],
				})
			} else {
				this.setState({
					suggestions: data,
				})
			}
		})
	}

	// Triggered on clear
	onSuggestionsClearRequested = () => {
		this.setState({
			suggestions: [],
		})
	}

	render() {
		const {value, suggestions, autosuggestId} = this.state
		const { isMobile } = this.props
		// Option props
		const inputProps = {
			placeholder: isMobile ? 'Reference Number *' :'Search By Reference',
			value,
			onChange: this.onChange,
			className: 'custom-input'
		}

		// Adding AutoSuggest component
		return (
			<Autosuggest
				id={autosuggestId}
				suggestions={suggestions}
				onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
				onSuggestionsClearRequested={this.onSuggestionsClearRequested}
				getSuggestionValue={this.getSuggestionValue}
				renderSuggestion={this.renderSuggestion}
				inputProps={inputProps}
			/>
		)
	}
}

export default ServerAutoSuggest
