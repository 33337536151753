import React from 'react'
import {Globals} from '../Context'
import {useEffect, useState, useRef} from 'react'
import Classnames from 'classnames'
import moment from 'moment'

import {addToast, getUnopenedConversationCount, getNotifications} from './../app/appActions'

import { connect} from 'react-redux'
import {useNavigate} from 'react-router-dom'


const AppNotifications = props => {
	const navigate = useNavigate()
	const wrapperRef = useRef(null)

	const {
		currentSpace,
		currentProfile,
		notificationsData
	} = props

	const [showNotificationPopup, setShowNotificationPopup] = useState(false)
	let unOpenedCount = notificationsData.unopenedCount

	const markAsRead = async id => {
		try {
			if (id === 'all') {
				await Globals.New_Axios().post(`${Globals.NEW_API_URL}/users/${currentProfile.id}/notifications/open_all`,
					{
						filtered_by_group_id: currentSpace?.id,
						filtered_by_group_type: 'Space',
					}
				)

			} else {
				await Globals.New_Axios().put(`${Globals.NEW_API_URL}/users/${currentProfile.id}/notifications/${id}/open`)
			}
			props.getUnopenedConversationCount()
			props.getNotifications()
		} catch (error) {
			console.log(error)
		}
	}

	const notificationTitle = notification => {
		if (notification.notifiable_type === 'Message') {
			return `Message from: ${notification.notifiable.space_name}`
		}

		return 'Notification'
	}

	const handleOpenNotification = async (notification) => {
		if (!notification.opened_at) {
			await markAsRead(notification.id)
		}
		navigate(notification.notifiable_path, {state: {fromNotification: true}})
		props.getNotifications()
	}

	useEffect(() => {
		const handleClickOutsideWrapper = (event) => {
			if( wrapperRef.current && !wrapperRef.current.contains(event.target) && showNotificationPopup && !event.target.classList.contains('notification-view')) {
				handleClickOutside();
			}
		};

		document.addEventListener('mousedown', handleClickOutsideWrapper);
		return () => {
		  document.removeEventListener('mousedown', handleClickOutsideWrapper);
		};
	  }, [showNotificationPopup]);

	  const handleClickOutside = () => {
		setShowNotificationPopup(false)
	}

	return (
		<button className="active relative notification-view" onClick={() => setShowNotificationPopup(!showNotificationPopup)}>
			<svg className="mx-3 w-6 h-6 notification-view" width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
				<path
					d="M12 15H17L15.5951 13.5951C15.2141 13.2141 15 12.6973 15 12.1585V9C15 6.38757 13.3304 4.16509 11 3.34142V3C11 1.89543 10.1046 1 9 1C7.89543 1 7 1.89543 7 3V3.34142C4.66962 4.16509 3 6.38757 3 9V12.1585C3 12.6973 2.78595 13.2141 2.40493 13.5951L1 15H6M12 15V16C12 17.6569 10.6569 19 9 19C7.34315 19 6 17.6569 6 16V15M12 15H6"
					stroke="currentColor"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>

			{notificationsData.unopenedCount > 0 && unOpenedCount > 0 && (
				<div className="absolute top-[-5px] right-[10px] inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
					{notificationsData.unopenedCount > 99 ? '99+' : unOpenedCount }
				</div>
			)}

			<div ref={wrapperRef} className="absolute right-0 lg:top-8 top-28 w-[350px] rounded-md shadow-lg bg-white z-20">
				{showNotificationPopup && (
					<React.Fragment>
						<div className="text-gray-800 text-left px-4 py-1 border-b flex justify-between font-medium">
							<div>Notifications</div>
							<div
								className="text-blue-500 cursor-pointer"
								onClick={e => {
									e.stopPropagation()
									markAsRead('all')
								}}
							>
								Mark all as read
							</div>
						</div>
						<ul className="text-gray-700 max-h-[600px] overflow-auto">
							{(notificationsData.notifications || []).map((notification, index) => {
								return (
									<li
										key={notification.id}
										className={Classnames('hover:bg-gray-200 border-b cursor-pointer')}
										onClick={() => handleOpenNotification(notification)}
									>
										<div className="flex px-4 py-2">
											<div className="w-[30px] text-blue-500 rounded-lg flex flex-row items-center shrink-0">
												<svg aria-hidden="true" className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
													<path
														d="M6 7H6.01M10 7H10.01M14 7H14.01M7 13H3C1.89543 13 1 12.1046 1 11V3C1 1.89543 1.89543 1 3 1H17C18.1046 1 19 1.89543 19 3V11C19 12.1046 18.1046 13 17 13H12L7 18V13Z"
														strokeWidth="2"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											</div>
											<div className="text-left flex-grow-1">
												<p className={Classnames('text-sm', {'font-bold': !notification["opened?"]})}>{notificationTitle(notification)}</p>
												<p className="text-xs">{notification.printable_notifiable_name}</p>
												<p className="text-xs">{moment(notification.created_at).format('MMM DD HH:mm')}</p>
											</div>
										</div>
									</li>
								)
							})}
						</ul>
					</React.Fragment>
				)}
			</div>
		</button>
	)
}

const mapStateToProps = (state, props) => {
	return {
		currentSpace: state.currentSpace,
		currentProfile: state.profile,
		notificationsData: state.notificationsData
	}
}

const mapActionsToProps = {
	addToast,
	getUnopenedConversationCount,
	getNotifications,
}

export default connect(mapStateToProps, mapActionsToProps)(AppNotifications)
