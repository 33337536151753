import React, {useRef, useState, useEffect} from 'react'
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Classnames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Tooltip from '@mui/material/Tooltip'

import FilterWebStore from './FilterWebStore';
import FilterModal from './FilterModal';
import FilterByOptionModal from './FilterByOptionModal';
import ContactDialogViewCard from "./ContactDialogViewCard";
import DetailWebStoreCard from "./DetailWebStoreCard";
import useOutsideClick from '../../../src/hooks/useOutsideClick';

const Info = ({data}) => {
    const textRef = useRef(null);
    const [isTruncated, setIsTruncated] = useState(false);

    useEffect(() => {
        let { scrollWidth, clientWidth } = textRef.current;
        setIsTruncated(scrollWidth > clientWidth);
    }, [data]);

	return (
        !isTruncated ?
        <div ref={textRef} className="text-[12px] font-bold truncate">{data}</div>
        :
        <Tooltip title={data} placement="bottom" ardata>
            <div ref={textRef} className="text-[12px] font-bold truncate">{data}</div>
        </Tooltip>
	)
}

const Title = ({data}) => {
    const titleRef = useRef(null);
    const [isLineClamp, setIsLineClamp] = useState(false);

    useEffect(() => {
      let { scrollHeight, clientHeight } = titleRef.current;
      setIsLineClamp(scrollHeight > clientHeight);
    }, [data]);

	return (
        !isLineClamp ?
            <span ref={titleRef} className="text-[10px] font-medium line-clamp-2 h-[30px]">{data.marketplace_name}</span>
        :
        <Tooltip title={data?.marketplace_name} placement="bottom" arrow>
            <span ref={titleRef} className="text-[10px] font-medium line-clamp-2">{data.marketplace_name}</span>
        </Tooltip>
	)
}

const ListViewCard = ({
    handleScroll,
    filterDataSelected,
    handleChangeFilterData,
    handleClearAllFilter,
    webStoreContainer,
    logo,
    space,
    contactCardData,
    toggleContactCartDialog,
    currentDropdownFilter,
    setCurrentDropdownFilter,
    handleSearch,
    filterData,
    keySearch,
    handleClearSearch,
    webStoreSetting,
    isPresentFilterDataSelected,
    loadingMoreData,
    dataProvider,
    loading,
    openContactCartDialog,
    setOpenContactCartDialog,
    isMobile,
    visibleItem,
    closeItemDetail,
    activeDataItem,
    renderSerialNumber,
    openGalleryItemCardView,
    closeFilterModal,
    handleChooseFilterOption,
    filtersConfigData,
    currentFilterDataSelected,
    handleApplyFilter,
    openFilterModal,
    openFilterByOptionModal,
    closeFilterByOptionModal,
    filterOption,
    handleApplyFilterByOption,
    visibleGallery,
    activeGalleryItem,
    openItemDetail,
    activeGalleryItemIndex,
    paginateSlider,
    closeGalleryCardView,
    firstLoadFilterData,
    filterPriorities,
    visibleFields,
}) => {
    const dialogRef = useRef(null);

    useOutsideClick(dialogRef, () => {
            closeGalleryCardView()
    });


    return (
        <div className={'bg-white w-full h-screen rounded-tl-[36px] overflow-x-hidden'} onScroll={handleScroll} ref={webStoreContainer}>
            <div
                className="relative h-[355px] bg-[#E8E8E8] md:bg-white px-5 md:px-10 text-black flex flex-col justify-between pt-6"
                style={{backgroundImage: `url(/Images/background_web_store_card.png)`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: '#E8E8E8'
                }}
            >
                <div className="flex w-full justify-start items-start mb-4 md:mb-0 md:w-auto">
                    <div className='flex items-center'>
                        {logo.url &&
                            <div className="h-[130px] w-[130px]">
                                <img src={logo.url} alt={space.name} className="w-auto h-auto h-[130px] w-[130px] object-cover" />
                            </div>
                        }
                        <div className="ml-3">
                            <div className="font-bold text-2xl mb-3 md:text-[45px]">{space.name}</div>
                            <div className="flex items-center">
                                {
                                    <div
                                        className="border border-[#5D6E81] cursor-pointer font-bold text-[#5D6E81] px-2 text-center text-[17px] rounded-[8px]"
                                        onClick={toggleContactCartDialog}
                                    >
                                        <span className='border-1 border-[#fff]'>
                                            Contact Us
                                        </span>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {
                    <div className='w-full'>
                        <FilterWebStore
                            onChangeFilterData={handleChangeFilterData}
                            filterDataSelected={filterDataSelected}
                            currentDropdownFilter={currentDropdownFilter}
                            setCurrentDropdownFilter={setCurrentDropdownFilter}
                            onSearch={handleSearch}
                            filterData={filterData}
                            keySearch={keySearch}
                            onClearSearch={handleClearSearch}
                            viewType={webStoreSetting?.view_ui}
                            firstLoadFilterData={firstLoadFilterData}
                            filterPriorities={filterPriorities}
                            visibleFields={visibleFields}
                        />
                    </div>
                }
            </div>

            {/* Filter Mobile Screen */}
            {

                <div className="flex flex-wrap items-center max-w-full px-20 mt-2 gap-2">
                    {isPresentFilterDataSelected() && (
                        <>
                            <div className="italic text-xs flex-shrink-0">Filtered By:</div>
                            {Object.keys(filterDataSelected).flatMap(key =>
                                filterDataSelected[key].map((item, index) => (
                                    <div key={`${key}-${item}-${index}`} className="flex items-center px-3 py-1 rounded-md italic text-xs">
                                        <span className="mr-1">{item}</span>
                                        <button
                                            className="font-bold italic ml-2"
                                            onClick={() => handleChangeFilterData(key, item)}
                                        >
                                            X
                                        </button>
                                    </div>
                                ))
                            )}
                            <div
                                className="italic text-[10px] flex-shrink-0 cursor-pointer hover:font-bold"
                                onClick={handleClearAllFilter}
                            >
                                RESET FILTERS
                            </div>
                        </>
                    )}
                </div>
            }

            {/* Loading Effect */}
            {loadingMoreData && (
                <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center z-50">
                    <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 leading-6 text-sm shadow rounded-md text-white bg-blue transition ease-in-out duration-150 cursor-not-allowed"
                        disabled
                    >
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            {' '}
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            />
                        </svg>
                        <span>Loading... </span>
                    </button>
                </div>
            )}

            {/* List Item */}

            <div className="watch-list-container flex justify-center px-5 md:px-10">
                <Grid container rowGap={4} columnSpacing={4} width={996}>
                    {(!isEmpty(dataProvider)) ?
                        dataProvider.map((row, index) => (
                            <Grid item xs={3} key={index}>
                                <div
                                    className={Classnames('watch-list-item flex flex-col justify-center h-full border border-transparent hover:border-[#5D6E81] px-[6px] py-[4px] cursor-pointer')}
                                    onClick={() => {
                                        if (row.images && row.images.length > -1) {
                                            openItemDetail(row)
                                        }
                                    }}
                                >
                                    {webStoreSetting.images_visible &&
                                        <div className="relative watch-list-item-image flex justify-center h-[219px]">
                                            {
                                                row.images && row.images.length > 0
                                                    ?
                                                    <React.Fragment>
                                                        <img src={row.images[0].url} className="object-contain h-full sm:min-h-[174px]" alt="" />
                                                    </React.Fragment>
                                                    :
                                                    <img src="/watch_icon.png" className="object-contain h-full sm:min-h-[174px]" alt="" />
                                            }
                                        </div>
                                    }
                                    <div className='mt-[7px]'>
                                        <Info data={`${row?.brand} ${row?.series ? row?.series : ''}`}/>
                                        <div className="flex justify-between">
                                            <Title data={row}/>
                                        </div>
                                        {visibleFields.includes('wholesale_price') &&
                                            (webStoreSetting?.wholesale_price_visible &&
                                                row.wholesale_price_formatted ? (
                                                    <span className="text-[#5D6E81] font-bold text-[12px]">
                                                        {`$${row.wholesale_price_formatted}`}
                                                    </span>
                                                ) : (
                                                    <span className=" text-[12px] font-bold text-[#5D6E81]">
                                                        Contact for price
                                                    </span>
                                                )
                                            )

                                        }
                                    </div>
                                </div>
                            </Grid>
                        ))
                    :
                        (
                            <Grid item xs={12} className="flex justify-center">
                                No data found
                            </Grid>
                        )
                    }
                </Grid>
            </div>

            {/* Dialog */}
            <ContactDialogViewCard
                open={openContactCartDialog}
                setOpen={setOpenContactCartDialog}
                space={space}
                logo={logo}
                contactCardData={contactCardData}
                isMobile={isMobile}
                webStoreSetting={webStoreSetting}
            />

            <Dialog
                fullScreen
                open={visibleGallery}
                classes={{ paper: 'transparent-dialog' }}
                onKeyUp={e => {
                    if (e.key === 'Escape') closeGalleryCardView()
                }}
            >
                <div className="bg-white sm:bg-[#D9D9D9E5] h-full w-full p-10">
                    <div className="h-full w-full relative">
                        {activeGalleryItem && (
                            <div className="h-full w-full md:p-10">
                                {activeGalleryItem.map((image, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            {index === activeGalleryItemIndex && (
                                                <div className="flex h-full items-center justify-center w-full relative">
                                                    <div className="relative h-full w-full flex justify-center">
                                                        <React.Fragment>
                                                            <div ref={dialogRef} className="h-full w-full max-w-[550px] max-h-[550px] md:max-w-[793px] md:max-h-[791px] flex justify-center relative">
                                                                {activeGalleryItem.length > 1 &&
                                                                    <button
                                                                        className="border-black border-2 rounded-full p-2 absolute z-10 bottom-1/2 transform left-[-60px] translate-y-1/2 active:scale-90"
                                                                        onClick={paginateSlider.bind(this, 'previous')}
                                                                    >
                                                                        <ChevronLeftIcon sx={{ fontSize: '32px' }} />
                                                                    </button>
                                                                }
                                                                <div className="relative w-auto h-auto max-w-[550px] max-h-[550px] md:max-w-[793px] md:max-h-[791px]">
                                                                    {image.type.includes('video') ? (
                                                                        <video controls className="w-full h-full">
                                                                            <source src={image.video_url} type="video/mp4" />
                                                                        </video>
                                                                    ) : (
                                                                        <img key={image.url} src={image.url} alt="." className="w-full h-full"/>
                                                                    )}
                                                                </div>
                                                                {activeGalleryItem.length > 1 &&
                                                                    <button
                                                                        className="border-black border-2 rounded-full p-2 absolute z-10 right-[-60px] bottom-1/2 transform translate-y-1/2 active:scale-90"
                                                                        onClick={paginateSlider.bind(this, 'next')}
                                                                    >
                                                                        <ChevronRightIcon sx={{ fontSize: '32px' }} />
                                                                    </button>
                                                                }
                                                            </div>
                                                        </React.Fragment>
                                                    </div>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                </div>
            </Dialog>

            <>
                <DetailWebStoreCard
                    visibleItem={visibleItem}
                    closeItemDetail={closeItemDetail}
                    activeDataItem={activeDataItem}
                    webStoreSetting={webStoreSetting}
                    renderSerialNumber={renderSerialNumber}
                    openGalleryItemCardView={openGalleryItemCardView}
                    paginateSlider={paginateSlider}
                    activeGalleryItemIndex={activeGalleryItemIndex}
                    activeGalleryItem={activeGalleryItem}
                    visibleGallery={visibleGallery}
                    visibleFields={visibleFields}
                />
                <FilterModal
                    open={openFilterModal}
                    onClearFilter={handleClearAllFilter}
                    onClose={closeFilterModal}
                    onChooseFilter={handleChooseFilterOption}
                    filtersConfigData={filtersConfigData}
                    dataSelected={currentFilterDataSelected}
                    onApplyFilter={handleApplyFilter}
                />
                {filterOption &&
                    <FilterByOptionModal
                        open={openFilterByOptionModal}
                        onClose={closeFilterByOptionModal}
                        filterOption={filterOption}
                        dataSelected={currentFilterDataSelected}
                        onApplyFilter={handleApplyFilterByOption}
                    />
                }
            </>
            <div className='absolute bottom-[10px] right-[10px] w-[181px] h-[39px] rounded-[10px] bg-[#5D6E8130] flex items-center px-1'>
                <span className='text-[12px] font-normal leading-[18px] font-poppins text-[#5D6E81]'>Powered By</span>
                <img className='h-[23px]'  src="/Images/logo_footer.png"/>
            </div>
        </div>
    )
}

export default ListViewCard
