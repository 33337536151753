import {useSelector, useDispatch} from 'react-redux'
import {toggleSidebar} from '../../app/appActions'
import SideBar from '../SideBar'
import TopBar from '../TopBar'
import TopElements from './TopElements'
import AddInventoryComponent from './AddInventoryComponent'
import React from 'react'

const AddInventory = () => {
	const dispatch = useDispatch(),
		{isOpenSideBar, loggedin} = useSelector(state => state),
		toggleSideBar = () => dispatch(toggleSidebar(!isOpenSideBar))

	return (
		<React.Fragment>
			{loggedin && (
				<section className="relative">
					<React.Fragment>
						<TopBar toggleSideBar={toggleSideBar} />
						<div className="flex relative">
							<SideBar isOpen={isOpenSideBar} />
							<main className="mt-16 flex flex-col relative w-full ">
								<TopElements />
								<AddInventoryComponent />
							</main>
						</div>
					</React.Fragment>
				</section>
			)}
		</React.Fragment>
	)
}

export default AddInventory
