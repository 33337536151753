import thunk from 'redux-thunk'

import {routerReducer} from 'react-router-redux'
import {applyMiddleware, compose, combineReducers, createStore} from 'redux'
import {
	userLoggedIn,
	userProfile,
	toggleSidebar,
	toggleDetailsCardInventory,
	toggleNote,
	toggleRepairs,
	toggleTableDropDown,
	toggleDataProvider,
	toasts,
	inventoryFilters,
	clearInventoryFilters,
	currentSpaceReducer,
	marketplaceFiltersReducer,
	notificationsDataReducer,
	conversationsCounterReducer,
	watchColumnsConfigsReducer,
} from './appReducers'
import uniqueId from 'lodash/uniqueId'

const allReducers = combineReducers({
	router: routerReducer,
	loggedin: userLoggedIn,
	profile: userProfile,
	isOpenSideBar: toggleSidebar,
	isOpenDetailsInventory: toggleDetailsCardInventory,
	isOpenNote: toggleNote,
	isOpenRepairs: toggleRepairs,
	visibleTableDropDown: toggleTableDropDown,
	dataProvider: toggleDataProvider,
	toasts: toasts,
	inventoryFilters: inventoryFilters,
	clearInventoryFilters: clearInventoryFilters,
	currentSpace: currentSpaceReducer,
	notificationsData: notificationsDataReducer,
	marketplaceFilters: marketplaceFiltersReducer,
	conversationsCounter: conversationsCounterReducer,
	globalColumnConfigs: watchColumnsConfigsReducer,
})

const allStoreEnhancers = compose(applyMiddleware(thunk), (window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) || compose)

export const store = createStore(
	allReducers,
	{
		globalColumnConfigs: [],
		loggedin: false,
		profile: {},
		isOpenSideBar: window.innerWidth <= 768 ? false : true,
		isOpenDetailsInventory: false,
		isOpenNote: false,
		isOpenRepairs: false,
		visibleTableDropDown: null,
		dataProvider: [],
		toasts: [],
		inventoryFilters: [],
		clearInventoryFilters: Math.random(),
		currentSpace: {},
		notificationsData: {
			totalCount: 0,
			unopenedCount: 0,
			notifications: [],
		},
		conversationsCounter: {
			sellThreadsCount: 0,
			unreadSellThreadsCount: 0,
			buyThreadsCount: 0,
			unreadBuyThreadsCount: 0,
		},
		marketplaceFilters: {
			unwornPreOwned: null, // unworn | pre-owned
			papers: false,
			box: false,
			searchKeywords: '',
			specialFilters: {
				type: null, // null, brand, newArrivals, popular, allBrands, space
				title: null,
				watchId: null,
				spaceId: null,
			},
			filterDataSelected: {
				brand: [],
				reference_number: [],
				series: [],
				condition: [],
				box_papers: [],
				dial: [],
				bracelet: [],
				wholesale_price: [],
			},
			filterPriorities: [],
			activeTab: 'home',
			triggerRenderer: uniqueId('marketplace_'),
		},
	},
	allStoreEnhancers
)
