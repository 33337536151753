import isNil from 'lodash/isNil'
import isBoolean from 'lodash/isBoolean'

export const toYesNo = (value, falseOnEmpty = false) => {
	if (falseOnEmpty && isNil(value)) {
		return false
	}

	if (isBoolean(value)) {
		if (value) {
			return 'Yes'
		} else {
			return 'No'
		}
	} else {
		return value
	}
}

export const getConditionLabel = key => {
	switch (key) {
		case 'unworn':
			return 'Unworn'
		case 'like_new':
			return 'Like New'
		case 'excellent':
			return 'Excellent'
		case 'very_good':
			return 'Very Good'
		case 'good':
			return 'Good'
		case 'fair':
			return 'Fair'
		default:
			return key
	}
}
