import {useState} from 'react'
import TermsOfUse from '../Pages/TermsOfUse'
import PrivacyPolicy from '../Pages/PrivacyPolicy'

const Privacy = () => {
	const [showPrivacy, setShowPrivacy] = useState(false)
	const [showTerms, setShowTerms] = useState(false)
	const hidePrivacy = () => {
		setShowPrivacy(false)
	}
	const hideTerms = () => {
		setShowTerms(false)
	}

	return (
		<div className="relative bottom-0 w-full mt-5">
			<ul className="flex flex-row justify-center py-4 lg:pt-8">
				<li className="text-xs px-2">
					<a
						onClick={() => {
							setShowPrivacy(true)
						}}
						href="#"
					>
						Privacy Policy
					</a>
				</li>
				<li className="text-xs px-2">
					<a
						onClick={() => {
							setShowTerms(true)
						}}
						href="#"
					>
						Terms of Use
					</a>
				</li>
				<li className="text-xs px-2">2022© Elefta.</li>
			</ul>
			{showPrivacy && <PrivacyPolicy hidePrivacy={hidePrivacy} />}
			{showTerms && <TermsOfUse hideTerms={hideTerms} />}
		</div>
	)
}
export default Privacy
