import {Globals} from './../Context'
import uniqueId from 'lodash/uniqueId'

export const USERLOGIN = '@APP/USERLOGIN'
export const USERLOGOUT = '@APP/USERLOGOUT'
export const STOREPROFILE = '@APP/STOREPROFILE'
export const TOGGLESIDEBAR = '@APP/TOGGLESIDEBAR'
export const TOGGLEDCINVENTORY = '@APP/TOGGLEDCINVENTORY'
export const TOGGLENOTE = '@APP/TOGGLENOTE'
export const TOGGLEREPAIRS = '@APP/TOGGLEREPAIRS'
export const TOGGLETABLEDROPDOWN = '@APP/TOGGLETABLEDROPDOWN'
export const TOGGLEDATAPROVIDER = '@APP/TOGGLEDATAPROVIDER'
export const ADD_TOAST = '@APP/ADD_TOAST'
export const REMOVE_TOAST = '@APP/REMOVE_TOAST'
export const INVENTORY_FILTERS = '@APP/INVENTORY_FILTERS'
export const CLEAR_INVENTORY_FILTERS = '@APP/CLEAR_INVENTORY_FILTERS'
export const SET_CURRENT_SPACE = '@APP/SET_CURRENT_SPACE'
export const SET_NOTIFICATIONS_DATA = '@APP/SET_NOTIFICATIONS_DATA'
export const UPDATE_NOTIFICATION_DATA = '@APP/UPDATE_NOTIFICATION_DATA'
export const UPDATE_MARKETPLACES_FILTERS = '@APP/UPDATE_MARKETPLACES_FILTERS'
export const UPDATE_CONVERSATIONS_COUNTER = '@APP/UPDATE_CONVERSATIONS_COUNTER'
export const SET_WATCH_COLUMNS_CONFIGS = '@APP/SET_WATCH_COLUMNS_CONFIGS'

export const logIn = () => {
	return {
		type: USERLOGIN,
		payload: {
			loggedin: true,
		},
	}
}

export const refreshToken = async (axiosInstance) => {
	try {
		const response = await axiosInstance.post(
			`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/refresh_token`,
			{
				refresh_token: Globals.Cache.get(Globals.REFRESH_TOKEN),
			},
			{
				skipRefreshTokenInterceptor: true,
			}
		)

		const { access_token, refresh_token, refresh_token_expiry, access_token_expiry } = response.data
		const accessTokenHeader = `Bearer ${access_token}`

		Globals.Cache.set(Globals.New_TokenKey, accessTokenHeader)
		Globals.Cache.set(Globals.New_TokenKey_Expiry, access_token_expiry)
		Globals.Cache.set(Globals.REFRESH_TOKEN, refresh_token)
		Globals.Cache.set(Globals.REFRESH_TOKEN_EXPIRY, refresh_token_expiry)
		Globals.Cache.setCookie(Globals.New_TokenKey, accessTokenHeader)
		Globals.Cache.setCookie(Globals.New_TokenKey_Expiry, access_token_expiry)
		Globals.Cache.setCookie(Globals.REFRESH_TOKEN, refresh_token)
		Globals.Cache.setCookie(Globals.REFRESH_TOKEN_EXPIRY, refresh_token_expiry)
		return accessTokenHeader
	} catch (error) {
		logOut()
	}
}

export const logOut = (needRedirect=true) => {
	Globals.Cache.deleteStorage(Globals.TokenKey)
	Globals.Cache.deleteCookie(Globals.TokenKey)

	Globals.Cache.deleteCookie(Globals.New_TokenKey)
	Globals.Cache.deleteStorage(Globals.New_TokenKey)

	Globals.Cache.deleteCookie(Globals.New_TokenKey_Expiry)
	Globals.Cache.deleteStorage(Globals.New_TokenKey_Expiry)

	Globals.Cache.deleteCookie(Globals.New_TokenKey_Expiry)
	Globals.Cache.deleteStorage(Globals.New_TokenKey_Expiry)

	Globals.Cache.deleteCookie(Globals.REFRESH_TOKEN)
	Globals.Cache.deleteStorage(Globals.REFRESH_TOKEN)

	Globals.Cache.deleteCookie(Globals.REFRESH_TOKEN_EXPIRY)
	Globals.Cache.deleteStorage(Globals.REFRESH_TOKEN_EXPIRY)

	Globals.Cache.deleteCookie(Globals.USERPROFILE)
	Globals.Cache.deleteStorage(Globals.USERPROFILE)

	Globals.Cache.deleteCookie(Globals.USERSELECTEDSPACE)
	Globals.Cache.deleteStorage(Globals.USERSELECTEDSPACE)

	Globals.Cache.deleteCookie(Globals.Sensitive_Data_Column)
	Globals.Cache.deleteStorage(Globals.Sensitive_Data_Column)

	localStorage.setItem('logoutEvent', Date.now());

	if (needRedirect) {
		window.location.href = '/login'
	}

	return {
		type: USERLOGOUT,
		payload: {
			loggedin: false,
		},
	}
}

export const storeProfile = profile => {
	return {
		type: STOREPROFILE,
		payload: {
			profile: profile,
		},
	}
}

export const toggleSidebar = state => {
	return {
		type: TOGGLESIDEBAR,
		payload: {
			isOpenSideBar: state,
		},
	}
}

export const toggleDetailsCardInventory = state => {
	return {
		type: TOGGLEDCINVENTORY,
		payload: {
			isOpenDetailsInventory: state,
		},
	}
}

export const toggleNote = state => {
	return {
		type: TOGGLENOTE,
		payload: {
			isOpenNote: state,
		},
	}
}

export const toggleRepairs = state => {
	return {
		type: TOGGLEREPAIRS,
		payload: {
			isOpenRepairs: state,
		},
	}
}

export const toggleTableDropDown = state => {
	return {
		type: TOGGLETABLEDROPDOWN,
		payload: {
			visibleTableDropDown: state,
		},
	}
}

export const toggleDataProvider = state => {
	return {
		type: TOGGLEDATAPROVIDER,
		payload: {
			dataProvider: state,
		},
	}
}

export const inventoryUpdateFilters = state => {
	return {
		type: INVENTORY_FILTERS,
		payload: state,
	}
}

export const clearInventoryFilters = state => {
	return {
		type: CLEAR_INVENTORY_FILTERS,
		payload: state,
	}
}

let toastId = 0

export default function createToast(options) {
	return {
		...options,
		id: toastId++,
	}
}

export const addToast = (options = {}) => {
	return {
		type: ADD_TOAST,
		payload: createToast(options),
	}
}

export const removeToast = id => {
	return {
		type: REMOVE_TOAST,
		payload: id,
	}
}

export const setCurrentSpace = space => {
	return {
		type: SET_CURRENT_SPACE,
		payload: space,
	}
}

export const setNotificationsData = data =>{
	return{
		type : SET_NOTIFICATIONS_DATA,
		payload: data
	}
}

export const  setMarketplaceFilters = (params) => {
	return (dispatch, getState) => {
		dispatch({
			type: UPDATE_MARKETPLACES_FILTERS,
			payload: {
				triggerRenderer: uniqueId('marketplace_'),
				...params
			},
		})
	}
}

export const  setMarketplaceSearchKeywords = (keyword) => {
	return (dispatch, getState) => {
		dispatch({
			type: UPDATE_MARKETPLACES_FILTERS,
			payload: {
				triggerRenderer: uniqueId('marketplace_'),
				searchKeywords: keyword,
			}
		})
	}
}

export const  setMarketplaceActiveTab = (tabName) => {
	return (dispatch, getState) => {
		const newState = {
			specialFilters: {
				type: null,
				title: null,
				watchId: null,
				spaceId: null,
			},
		}

		if (tabName === 'home') {
			newState['searchKeywords'] = ''
			newState['unwornPreOwned'] = null
			newState['papers'] = false
			newState['box'] = false
			newState['searchKeywords'] = ''
		}

		dispatch({
			type: UPDATE_MARKETPLACES_FILTERS,
			payload: {
				...newState,
				triggerRenderer: uniqueId('marketplace_'),
				activeTab: tabName,
			}
		})
	}
}

export const getNotifications = () => {
	return async (dispatch, getState) => {
		const {profile, currentSpace} = getState()
		try {
			let response = await Globals.New_Axios().get(`${Globals.NEW_API_URL}/users/${profile.id}/notifications`, {
				params: {
					format: 'json',
					filtered_by_group_id: currentSpace?.id,
					filtered_by_group_type: 'Space',
					without_grouping: true,
				},
			})

			const { data } = response

			dispatch({
				type: SET_NOTIFICATIONS_DATA,
				payload: {
					totalCount: data.notifications.length,
					notifications: data.notifications
				}
			})
		} catch (error) {
			console.log(error)
		}
	}
}

export const getUnopenedConversationCount = () => {
	return async (dispatch, getState) => {
		try {
			let response = await Globals.New_Axios().get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/conversations/unopened_count`)

			const { data } = response
			dispatch({
				type: SET_NOTIFICATIONS_DATA,
				payload: {
					unopenedCount: data.unopened_count,
				}
			})
		} catch (error) {
			console.log(error)
		}
	}
}

export const getConversationsCount = () => {
	return async (dispatch, getState) => {
		try {
			let response = await Globals.New_Axios().get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/conversations/count`)

			const { data } = response

			dispatch({
				type: UPDATE_CONVERSATIONS_COUNTER,
				payload: {
					sellThreadsCount: data.sell_threads_count,
					unreadSellThreadsCount: data.unread_sell_threads_count,
					buyThreadsCount: data.buy_threads_count,
					unreadBuyThreadsCount: data.unread_buy_threads_count,
				}
			})
		} catch (error) {
			console.log(error)
		}
	}
}

export const handleGetGlobalColumnConfigs = (currentSpace) => {
	return async (dispatch) => {
		try {
			let response = await Globals.New_Axios().get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${currentSpace.id}/global_watch_column_configs`)

			const { data } = response

			dispatch({
				type: SET_WATCH_COLUMNS_CONFIGS,
				payload: data
			})
		} catch (error) {
			console.log(error)
		}
	}
}
export const handleSetGlobalColumnConfigs = (data) => {
	return async (dispatch) => {
		dispatch({
			type: SET_WATCH_COLUMNS_CONFIGS,
			payload: data
		})
	}
}
