import {bindActionCreators} from 'redux'
import {Globals} from './Context'
import {useEffect} from 'react'
import ActionCable from 'actioncable'
import {useNavigate} from 'react-router-dom'

import {
	addToast,
	getNotifications,
	getUnopenedConversationCount,
	getConversationsCount,
	handleGetGlobalColumnConfigs
} from './app/appActions'

import {connect} from 'react-redux'

let space
let cableApp = {}

function RouterWrapper(props) {
	const navigate = useNavigate()

	const {
		currentSpace,
		currentProfile,
		notificationsData,
		children
	} = props

	space = currentSpace

	// use global space to fix stale closure
	const newMessageToast = async data => {
		if (!!space?.id && !!data.receiver_space_id && data.receiver_space_id === space.id) {
			const content = JSON.parse(data.content)
			let message = ''
			await content.map(item => {
				message = `${message} ${item.children[0].text}`
			})
			props.addToast({
				title: `Message from: ${data.space_name}`,
				text: message,
				type: 'messages',
				onClick: () => {
					navigate(`/messages/${data.conversation_id}`)
				}
			})
		}
	}

	useEffect(() => {
		const {
			getNotifications,
			getUnopenedConversationCount,
			getConversationsCount,
			handleGetGlobalColumnConfigs
		} = props
		getNotifications()
		getUnopenedConversationCount()
		getConversationsCount()

		handleGetGlobalColumnConfigs(currentSpace)
		return () => {
			cableApp?.cable1.disconnect()
		}
	}, [])

	useEffect(() => {
		const {
			getNotifications,
			getUnopenedConversationCount,
			getConversationsCount,
			handleGetGlobalColumnConfigs
		} = props

		cableApp.cable1 = ActionCable.createConsumer(`${Globals.actionCableURL}?access_token=${Globals.getAccessToken()}`)

		cableApp.cable1.subscriptions.create(
			{
				channel: 'ActivityNotification::NotificationApiChannel',
				target_type: 'User',
				target_id: currentProfile.id
			},
			{
				connected: function() {
					// Connected
				},
				disconnected: function() {
					// Disconnected
				},
				rejected: function() {
					// Rejected
				},
				received: function(response) {
					newMessageToast(response.data.notifiable)
					getNotifications()
					getUnopenedConversationCount()
					getConversationsCount()
				}
			}
		)

		cableApp.cable1.subscriptions.create({channel: 'WatchColumnsConfigurationChannel', space_id: currentSpace.id}, {
			connected: () => {
				console.log(`Connected to new message channel`)
			},
			received: async (data) => {
				handleGetGlobalColumnConfigs(currentSpace)
			}
		})

		return () => {
			cableApp?.cable1.disconnect()
		}
	}, [currentProfile.id, notificationsData])


	return children
}


const mapStateToProps = (state, props) => {
	return {
		currentSpace: state.currentSpace,
		currentProfile: state.profile,
		notificationsData: state.notificationsData
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		addToast,
		getNotifications,
		getUnopenedConversationCount,
		getConversationsCount,
		handleGetGlobalColumnConfigs
	}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RouterWrapper)
